import React, { Component } from 'react'
import {
  toggleBodyScroll,
  googleAnalytics,
  facebookPixel,
} from '../util'
import View from './View'
import imgs from './imgs'

export default class Home extends Component {
  static defaultProps = {
    imgs,
  }
  state = {
    isShowLightbox: false,
    currImgNum: 0,
  }

  componentDidMount() {
    this.scrollTarget = document.querySelector('#scrollTarget')
  }

  componentWillUpdate = (nextProps, nextState) =>
    nextState.isShowLightbox
      ? toggleBodyScroll()
      : toggleBodyScroll({ isDisable: false })

  componentWillUnmount = () =>
    this.state.isShowLightbox
      && toggleBodyScroll({ isDisable: false })

  handleCTAClick = () => {
    googleAnalytics({ evtCat: '/Home/AboveTheFold/CallToActionBtn'})
    facebookPixel('/Home/AboveTheFold/CallToActionBtn', 'click')
    window.scrollTo({
      top: this.scrollTarget.getBoundingClientRect().top + window.pageYOffset - 96,
      left: 0,
      behavior: 'smooth'
    })
  }

  handleLightboxBackgroundClick = () =>
    this.setState({
      ...this.state,
      isShowLightbox: false,
    })

  handleNextImgClick = () => {
    if (this.state.currImgNum < this.props.imgs.length - 1) {
      googleAnalytics({ evtCat: `/Lightbox/nextImgBtn`})
      facebookPixel('/Lightbox/nextImgBtn', 'click')
      this.setState({
        ...this.state,
        currImgNum: ++this.state.currImgNum,
      })
    }
  }

  handlePrevImgClick = () => {
    if (this.state.currImgNum > 0) {
      googleAnalytics({ evtCat: `/Lightbox/prevImgBtn`})
      facebookPixel('/Lightbox/prevImgBtn', 'click')
      this.setState({
        ...this.state,
        currImgNum: --this.state.currImgNum,
      })
    }
  }

  handleGalleryPreviewClick = evt => {
    const imgNum = +evt.target.value
    const fileName = imgs[imgNum].fileName

    googleAnalytics({ evtCat: `/Home/GalleryPreview/selectImg-${fileName}Btn`})
    facebookPixel(`/Home/GalleryPreview/selectImg-${fileName}Btn`, 'click')
    this.setState({
      ...this.state,
      isShowLightbox: true,
      currImgNum: imgNum,
    })
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        handleCTAClick={this.handleCTAClick}
        handleLightboxBackgroundClick={this.handleLightboxBackgroundClick}
        handleGalleryPreviewClick={this.handleGalleryPreviewClick}
        handleNextImgClick={this.handleNextImgClick}
        handlePrevImgClick={this.handlePrevImgClick}
      />
    )
  }
}
