import React from 'react'
import styled from 'styled-components'
import {
  BTN_TXT_COLOR,
  BTN_SHADOW,
  BTN_HOVER_SHADOW,
  BTN_ACTIVE_SHADOW,
  BTN_HOVER_SCALE,
  BTN_ACTIVE_SCALE,
  BTN_DURATION,
  BTN_EASING,
} from '../styles'
import { btnBackground } from '../imgs'
import { Icon } from './'

const A = styled.a`
  min-height: 40px;
  min-width: 40px;
  padding: 16px 48px;

  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${BTN_TXT_COLOR};
  background-image: url(${btnBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: ${BTN_SHADOW};

  &:hover{
    filter: ${BTN_HOVER_SHADOW};
    transform: scale(${BTN_HOVER_SCALE});
  }

  &:active{
    filter: ${BTN_ACTIVE_SHADOW};
    transform: scale(${BTN_ACTIVE_SCALE});
  }

  will-change: filter, transform;
  transition: all ${BTN_DURATION}ms ${BTN_EASING};

  &>svg{
    fill: ${BTN_TXT_COLOR};
  }
`

export default ({
  href = '',
  fileName = 'fileName',
  handleClick,
  children = 'download',
}) =>
  <A
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    download={fileName}
    onClick={handleClick}
  >
    <Icon
      cat="download"
      isLower
    />
    &nbsp;
    {children}
  </A>
