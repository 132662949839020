import React from 'react'
import styled from 'styled-components'
import { red } from '../styles'

const P = styled.p`
  text-align: center;
  color: ${red};
`

export default ({
  children = 'ErrMsgChild'
}) =>
  <P>{children}</P>
