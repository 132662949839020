import React from 'react'
import {
  brown5,
  brown6,
} from '../styles'

export default () =>
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 2000 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      fill={brown6}
      points="2000 29 1838.5 20.82 1786 26 1771.46 18.24 1475.64 19.07 1388 14 1249.35 27.29 1180 23 1113.62 21.59 1029.19 28.61 955.07 25.3 904.95 15.72 860.54 29 811.1 11.53 698.8 29 402.15 14.05 245.16 27.84 149.91 24.1 81.64 10.49 30 21 0 29 0 5 2000 5 2000 29"
    />
    <polygon
      fill={brown5}
      points="2000 24 1838.5 15.82 1786 21 1771.46 13.24 1475.64 14.07 1388 9 1249.35 22.29 1180 18 1113.62 16.59 1029.19 23.61 955.07 20.3 904.95 10.72 860.54 24 811.1 6.53 698.8 24 402.15 9.05 245.16 22.84 149.91 19.1 81.64 5.49 30 16 0 24 0 0 2000 0 2000 24"
    />
  </svg>
