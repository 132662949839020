import moment from 'moment'

export default (day, month, year) =>
{   
    day = day.toString();
    month = month.toString();
    year = year.toString();

    if (day.length == 1)
    {
      day = "0" + day;
    }
    
    if (month.length == 1)
    {
      month = "0" + month;
    }

    if (year.length == 2)
    {
        year = "20" + year;
    }

  return moment.utc(`${year}-${month}-${day} 00:00:00`).unix()
}