import React from 'react'
import styled from 'styled-components'

const P = styled.p`
  text-align: center;
`

export default ({
  children = 'PChild'
}) =>
  <P>{children}</P>
