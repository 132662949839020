import React from 'react'
import styled from 'styled-components'
import {
  brown3,
  shadow6,
  shadow3,
  durationLight,
  easingMoveLight,
  BTN_HOVER_SCALE,
} from '../styles'

const Btn = styled.button`
  min-width: 40px;
  min-height: 40px;

  padding: 16px 48px;
  white-space: nowrap;

  background: ${brown3};
  filter: ${shadow6};
  &:hover{
    filter: ${shadow3};
    transform: scale(${BTN_HOVER_SCALE});
  }
  will-change: filter, transform;
  transition: all ${durationLight}ms ${easingMoveLight};
`

// used as some browsers don't allow <button/> to use display: flex
const ContentsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

${'' /* prevents inteference with evt.target.value */}
  pointer-events: none;

  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.3px;
`

export default ({
  isDisabled = false,
  isSubmit = false,
  value = 'value',
  handleClick = () => console.log('click'),
  children = 'BtnChild',
}) =>
{
  return <Btn
    type={isSubmit
      ? 'submit'
      : 'button'
    }
    value={value}
    disabled={isDisabled}
    onClick={handleClick}
  >
    <ContentsWrapper>{children}</ContentsWrapper>
  </Btn>
}