import React from 'react'
import {
  RouteWrapper,
  Heading,
  Form,
  TxtInput,
  Submit,
  Icon,
} from '../Common'

export default ({
  password = {},
  confirmPassword = {},
  isAwaitingResponse = false,
  serverErr = null,
  successMsg = null,
  handleFocus = () => console.log('handleFocus'),
  handleBlur = () => console.log('handleBlur'),
  handleInput = () => console.log('handleInput'),
  handleSubmit = () => console.log('handleSubmit'),
  handleClear = () => console.log('default clear')
}) =>
  <RouteWrapper>
    <Heading txt="Reset Password" />
    <Form>

      <TxtInput
        name="password"
        value={password.val}
        error={password.err}
        isFocus={password.isFocus}
        isDisable={isAwaitingResponse}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        handleInput={handleInput}
        isPassword
      />

      <TxtInput
        name="confirmPassword"
        value={confirmPassword.val}
        error={confirmPassword.err}
        isFocus={confirmPassword.isFocus}
        isDisable={isAwaitingResponse}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        handleInput={handleInput}
        isPassword
      />

      <Submit
        isAwaitingResponse={isAwaitingResponse}
        error={serverErr}
        successMsg={successMsg}
        handleSubmit={handleSubmit}
        handleClear={handleClear}
      >
        <span>
          <Icon
            cat="account"
            size="22px"
            isLower
          />
          &nbsp;
          Reset Password
        </span>
      </Submit>

    </Form>
  </RouteWrapper>