import React from 'react'
import styled from 'styled-components'
import { yellow1, yellow2, textareaBackgroundColor, shadow6, SECOND_TXT_COLOR } from '../styles'
import RemoveContentBtn from './RemoveContentBtn'

const Div = styled.div`
  width: 100%;

${'' /* for remove btn */}
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;
    transform-origin: 0 0;
    transform: rotate(-90deg) translate(-110%, -130%);
    color: ${SECOND_TXT_COLOR};
  }

  textarea {
    width: 100%;
    height: calc(5 * 48px);
    padding: 4px;

    border: 1px solid ${yellow2};
    background: none;
    resize: none;

    &:focus {
      border: 1px solid ${yellow1};
    }

    background-color: ${textareaBackgroundColor};
    filter: ${shadow6};

  }
`

export default ({
  name = 0,
  val = '',
  handleInput = evt => console.log(`handleInput: name: ${evt.target.name}, ${evt.target.value}`),
  handleRemoveClick = evt => console.log(`handleRemoveClick: ${evt.target.value}`)
}) =>
  <Div>
    <label>paragraph</label>
    <textarea
      name={name}
      onChange={handleInput}
      value={val}
    />
    <RemoveContentBtn val={name} handleClick={handleRemoveClick} />
  </Div>
