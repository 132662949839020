import aTownshipTaleGroupSmall from './A-Township-Tale-Group-small.png'
import aTownshipTaleCavesSmall from './A-Township-Tale-Caves-small.png'
import aTownshipTaleMillSmall from './A-Township-Tale-Mill-small.png'
import aTownshipTaleForestSmall from './A-Township-Tale-Forest-small.png'
import aTownshipTaleMinesSmall from './A-Township-Tale-Mines-small.png'
import aTownshipTaleTownSmall from './A-Township-Tale-Town-small.png'
import aTownshipTaleGroupMed from './A-Township-Tale-Group-med.png'
import aTownshipTaleCavesMed from './A-Township-Tale-Caves-med.png'
import aTownshipTaleMillMed from './A-Township-Tale-Mill-med.png'
import aTownshipTaleForestMed from './A-Township-Tale-Forest-med.png'
import aTownshipTaleMinesMed from './A-Township-Tale-Mines-med.png'
import aTownshipTaleTownMed from './A-Township-Tale-Town-med.png'
import aTownshipTaleGroupLarge from './A-Township-Tale-Group-large.png'
import aTownshipTaleCavesLarge from './A-Township-Tale-Caves-large.png'
import aTownshipTaleMillLarge from './A-Township-Tale-Mill-large.png'
import aTownshipTaleForestLarge from './A-Township-Tale-Forest-large.png'
import aTownshipTaleMinesLarge from './A-Township-Tale-Mines-large.png'
import aTownshipTaleTownLarge from './A-Township-Tale-Town-large.png'

export default [
  {
    small: aTownshipTaleGroupSmall,
    med: aTownshipTaleGroupMed,
    large: aTownshipTaleGroupLarge,
    fileName: 'A-Township-Tale-Group.png',
    alt: 'Adventure A Township Tale Group'
  },
  {
    small: aTownshipTaleCavesSmall,
    med: aTownshipTaleCavesMed,
    large: aTownshipTaleCavesLarge,
    fileName: 'A-Township-Tale-Caves.png',
    alt: 'Caves A Township Tale Dark'
  },
  {
    small: aTownshipTaleMillSmall,
    med: aTownshipTaleMillMed,
    large: aTownshipTaleMillLarge,
    fileName: 'A-Township-Tale-Mill.png',
    alt: 'Wind Clouds Mill A Township Tale'
  },
  {
    small: aTownshipTaleForestSmall,
    med: aTownshipTaleForestMed,
    large: aTownshipTaleForestLarge,
    fileName: 'A-Township-Tale-Forest.png',
    alt: 'A Township Tale Forest Sword'
  },
  {
    small: aTownshipTaleMinesSmall,
    med: aTownshipTaleMinesMed,
    large: aTownshipTaleMinesLarge,
    fileName: 'A-Township-Tale-Mines.png',
    alt: 'A Township Tale Mines Dark Rail'
  },
  {
    small: aTownshipTaleTownSmall,
    med: aTownshipTaleTownMed,
    large: aTownshipTaleTownLarge,
    fileName: 'A-Township-Tale-Town.png',
    alt: 'A Township Tale Town Travel Mountains'
  }
]
