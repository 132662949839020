import React, { Component } from 'react'
import {
  googleAnalytics,
  facebookPixel,
} from '../util'
import View from './View'

export default class Community extends Component {
  static defaultProps = {

  }
  state = {

  }

  handleLinkClick = socialNetwork => {
    googleAnalytics({ evtCat: `/Community/${socialNetwork}Btn`})
    facebookPixel(`/Community/${socialNetwork}Btn`, 'click')
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        handleLinkClick={this.handleLinkClick}
      />
    )
  }
}
