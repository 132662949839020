import styled from 'styled-components'
import bottomBackground from './imgs/bottomBackground.svg'

export default styled.div`
  width: 100%;
  height: calc(2 * 48px);

  position: absolute;
  left: 0;
  bottom: 0;

${'' /* to render a repeated .svg without gaps, you need to set preserveAspectRatio="none" & background-size */}
  background-image: url(${bottomBackground});
  background-position: 0px 0px;
${'' /* set the sizes to the .svg viewbox & use multipliers to size */}
${'' /* img aspect ratio is 17:1 */}
  background-size: calc(17 * 2 * 48px) calc(2 * 48px);
  background-repeat: repeat-x;
`
