// colors follow the same pattern has headings, 1 = hightest emphasis, 6 = lowest emphasis


// TODO don't export natural colors, only specifics @ bottom
// body text
export const white1 = 'hsla(360, 100%, 100%, 0.87)'
// icons, disabled txt
export const white2 = 'hsla(360, 100%, 100%, 0.54)'
// icon sub layer (e.g. circle on loader)
export const white3 = 'hsla(360, 100%, 100%, 0.12)'


// <button />
export const yellow1 = 'hsla(39, 100%, 57%, 1)'
// <hr />
export const yellow2 = 'hsla(39, 100%, 57%, 0.54)'



// toolbar
export const brown1 = 'hsla(29, 77%, 29%, 1)'
// toolbar thickness
export const brown2 = 'hsla(29, 90%, 16%, 1)'
// btn
export const brown3 = '#68554f'

export const brown4 = 'hsla(348, 5%, 18%, 1)'
// route section background
export const brown5 = 'hsla(348, 7%, 15%, 1)'
// route section background thickness
export const brown6 = 'hsla(348, 10%, 12%, 1)'
// footer background
export const brown7 = 'hsla(348, 12%, 10%, 1)'

export const blue  = 'hsla(207, 90%, 54%, 1)'
export const red   = 'hsla(8, 68%, 45%, 1)'

export const APP_BACKGROUND_COLOR = brown5

export const PRIMARY_TXT_COLOR = white1
export const SECOND_TXT_COLOR = white2
export const ICON_COLOR = white1

export const ERR_COLOR = red
export const CLICKABLE_COLOR = yellow1

export const DROP_DOWN_SELECT_COLOR = brown4

export const textareaBackgroundColor = 'hsla(348, 6%, 16%, 1)'

// btns
export const BTN_TXT_COLOR = '#46341e'

// export const background = `linear-gradient(
//   to bottom,
//   hsla(348, 6%, 16%, 1) 70%,
//   hsla(348, 7%, 15%, 1) 75%,
//   hsla(348, 8%, 14%, 1) 80%,
//   hsla(348, 9%, 13%, 1) 85%,
//   hsla(348, 10%, 12%, 1) 90%,
//   hsla(348, 11%, 11%, 1) 95%,
//   hsla(348, 12%, 10%, 1) 100%
// )`
