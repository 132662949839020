import React, { Component } from 'react'
import View from './View'

export default class Footer extends Component {
  static defaultProps = {

  }
  state = {

  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
      />
    )
  }
}
