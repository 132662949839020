// @flow
/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react';

import sha512 from 'crypto-js/sha512'

import { Sessions, Security } from '../../webApiClient';

import QueryString from 'query-string';

import ShopInfo from '../../ShopInfo';

import {
    RouteWrapper,
    Heading,
    Form,
    TxtInput,
    Submit,
    Icon,
    SmallLink,
    Row
  } from '../../Common'
  
import {
    isName,
    isEmail,
    isPassword,
    cap1stLetter
  } from '../../util'


class LoginPage extends Component
{
    static defaultProps = 
    {
        
    }

    state = 
    {
        isProcessing: false,

        isRememberingPassword: false,

        username:
        {
            name:"username",
            value:"",
            error:"",
            isFocused:false,
            isDisabled:false,
        },
        
        password:
        {
            name:"password",
            value:"",
            error:"",
            isFocused:false,
            isDisabled:false,
        }
    }

    constructor(props)
    {
        super(props);

        if (Sessions.getLoggedIn())
        {
            this.handleLoggedIn();
        }
    }

    handleLoggedIn()
    {
        if (!!Sessions.getVerified())
        {
            var external = this.props.match.params.external;

            if (!external)
            {
                var redirect = QueryString.parse(this.props.location.search).redirect;

                if (!!redirect)
                {
                    console.log("Redirecting to " + redirect);

                    // window.location.href = redirect;
                }
                else
                {
                    this.props.context.refreshShop();
                    
                    this.props.history.push('/supporter');
                }
            }
            else
            {
                Security.sso()
                .then(result =>
                {
                    window.location.href = `https://api.townshiptale.com/sso/${external}${this.props.location.search}&info=${result.token}`;
                })
                .catch(error => 
                {
                    this.props.history.push('/');
                    console.log(error);
                });
            }
        }
        else
        {
            this.props.history.push('/account/verify')
        }
    }

    componentWillMount()
    {

    }

    componentWillReceiveProps(nextProps)
    {

    }

    componentWillUnmount()
    {

    }

    handleFocus = evt => 
    {
        const { name } = evt.target
    
        this.setState((prevState, props) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            isFocused: true,
          }
        }))
      }

    handleInput = evt => 
    {
        const { name, value } = evt.target;

        this.setState((prevState, props) => ({
        ...prevState,
        username:
        {
            ...prevState.username,
            error: null
        },
        [name]: {
            ...prevState[name],
            value: value,
            error: null,
        },
        }))
    }

    handleBlur = evt =>
    {
        const { name } = evt.target

        this.setState((prevState, props) => ({
        ...prevState,
        [name]: {
            ...prevState[name],
            isFocused: false,
        }
        }))
    }

    handleBack(evt)
    {
        evt.preventDefault();

        this.props.history.push("/account/register/" + this.state.username.value);
    }

    handleLogin(evt)
    {      
        evt.preventDefault();
          
        this.setState({isProcessing:true});

        let passwordHash = sha512(this.state.password.value).toString();

        let username = this.state.username.value.trim();

        (isEmail(username) ? 
        Sessions.loginWithEmail(username, passwordHash, this.state.isRememberingPassword) :
        Sessions.loginWithUsername(username, passwordHash, this.state.isRememberingPassword))
        .then(response =>
        {
            this.handleLoggedIn();
        })
        .catch(err => 
        {
            var message = JSON.parse(err.error).message;

            this.setState({isProcessing:false, username:{...this.state.username, error: message}});
        })
    }

    toggleRememberPassword()
    {
        this.setState({isRememberingPassword: !this.state.isRememberingPassword});
    }

    forgotPassword()
    {
        this.props.history.push("/account/forgot-password");
    }

    render()
    {
        if (Sessions.getLoggedIn())
        {
            return <RouteWrapper>
                <Heading txt={!this.props.match.params.external ? "Logging In" : "Connecting to " + cap1stLetter(this.props.match.params.external)} />
            </RouteWrapper>;
        }

        return (
        <RouteWrapper>
            <Heading txt={!this.props.match.params.external ? "Sign In" : "Connect to " + cap1stLetter(this.props.match.params.external)} />
            <Form>
                <TxtInput {...this.state.username} isDisabled={this.state.isProcessing} handleFocus={this.handleFocus} handleInput={this.handleInput} handleBlur={this.handleBlur}/>
                <TxtInput {...this.state.password} isDisabled={this.state.isProcessing} handleFocus={this.handleFocus} handleInput={this.handleInput} handleBlur={this.handleBlur} isPassword/>
                <Row size='calc(9 * 48px)' justify='space-between'>
                    <SmallLink label="Remember Me" isDisabled={this.state.isProcessing} isChecked={this.state.isRememberingPassword} handleClick={this.toggleRememberPassword.bind(this)}/>
                    <SmallLink label="Forgot Password" isDisabled={this.state.isProcessing} handleClick={this.forgotPassword.bind(this)}/>
                </Row>
                <Row size='calc(9 * 48px)' justify='space-around'>
                    <Submit
                        type={'button'}
                        isAwaitingResponse={this.props.isProcessing}
                        error={this.props.error}
                        successMsg={this.props.success}
                        handleSubmit={this.handleBack.bind(this)}>Register</Submit>
                    
                    <Submit
                        isAwaitingResponse={this.props.isProcessing /* || this.state.username.value == "" || this.state.password.value == ""*/}
                        error={this.props.error}
                        successMsg={this.props.success}
                        handleSubmit={this.handleLogin.bind(this)}>Login</Submit>
                </Row>
            </Form>
        </RouteWrapper>);
    }
}

export default ShopInfo.wrap(LoginPage);