import React from 'react'
import Wrapper from './Wrapper'
import Border from './Border'
import Links from './Links'

export default () =>
  <Wrapper>
    <Border/>
    <Links />
  </Wrapper>
