import blacksmithIcon  from "./imgs/blacksmithIcon.svg"
import farmerIcon      from "./imgs/farmerIcon.svg"
import minerIcon       from "./imgs/minerIcon.svg"
import warriorIcon     from "./imgs/warriorIcon.svg"
import otherIcon       from "./imgs/otherIcon.svg"
import blacksmithScene from "./imgs/blacksmithScene.png"
import farmerScene     from "./imgs/farmerScene.png"
import minerScene      from "./imgs/minerScene.png"
import warriorScene    from "./imgs/warriorScene.png"
import otherScene      from "./imgs/otherScene.png"

export default [
  {
    name: 'blacksmith',
    icon: blacksmithIcon,
    scene: blacksmithScene,
    description: 'A true blacksmith learns to understand the metals of the world. An ancient smelter from a far gone civilization sits in the town, and it takes an adept mind to understand its secrets. As a blacksmith, you must turn ores to ingots, and ingots to weapons and tools in order for the town to survive. Trust in the weight of your hammer, and forge metals of all kinds to craft better and better armaments. The town will depend on you and your expertise, each new weapon needs to be forged differently, and will behave uniquely.'
  },
  {
    name: 'farmer',
    icon: farmerIcon,
    scene: farmerScene,
    description: 'To keep the settlers equipped and safe from harm is one thing, but fighting hunger is another entirely. The farmer grows and nurtures crops, fending them from critters and growing the village’s supply. During trying times, the village will look to the farmer for survival. A good farmer knows best how and when to nurture different produce, and how to ration for surviving a long winter. An honored role, the farmer’s day begins early and ends late. The observant farmer is always discovering the benefits of new grain.'
  },
  {
    name: 'miner',
    icon: minerIcon,
    scene: minerScene,
    description: 'The Miner is at the forefront of the world’s exploration. Searching for greater ores, gems and resource, little will stop the Miner in their curiosity. The best miners can travel deep caves with little concern for their footing, but beware of what lurks in the dark. With a solid pickaxe in hand, spot the rich ore veins in the rocks and deftly smash them for rewards. As you reach deeper into the unknown, promise of long forgotten artefacts are the spoils of your vigor.'
  },
  {
    name: 'warrior',
    icon: warriorIcon,
    scene: warriorScene,
    description: 'The warrior is bestowed upon them the duty of protecting others in the face of danger. There are a great many creatures roaming the land, and all settlers can take up arms in defense of their home. But a skilled warrior is adept at weapon handling, being aware of how to battle against beasts as well as malicious settlers. A great shield is a key part of the defending warrior’s equipment. The dual-wielding berserker fighters are often less trusted to keep their companions away from harm, but are sure to deliver hefty blows.'
  },
  {
    name: 'other',
    icon: otherIcon,
    scene: otherScene,
    description: 'As a settler of this village, you are not locked down in any roles. It is up to you and others to decide what your responsibility will be, or if you will have any at all.. Each of these roles we describe refers to a set of core features in the game. Through updates, more and more will be coming to A Township Tale. No stone left unturned, we are exploring everything a fantasy world can offer. You could become a woodcutter, a fisherman, an explorer, forager, cook, alchemist and many more. Each with their own set of specialized disciplines, there are many more you’ll come up with in how you approach a particular field. If combat is your fancy, will be an armoured guard or a nimble ranger?'
  }
]
