import React from 'react'
import { Observable } from 'rxjs'

export default ({
  domElem = <div />,
} = {}) => {
  const touchStart$ = Observable.fromEvent(domElem, 'touchstart')
  const touchMove$ 	= Observable.fromEvent(domElem, 'touchmove')
  const touchEnd$ 	= Observable.fromEvent(window, 	'touchend')

  const touchMove$PreventScroll = touchMove$
    .map(evt => {
      evt.preventDefault()
      return evt
    })

  return touchStart$
    .switchMap(evt => {

        const startX = evt.touches[0].clientX
        const startY = evt.touches[0].clientY

        return touchMove$PreventScroll
          .takeUntil(touchEnd$)
          .takeLast(1)
          .map(evt => ({
              startX,
              startY,
              endX: evt.touches[0].clientX,
              endY: evt.touches[0].clientY
          }))
    })
}
