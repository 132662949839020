import React, { Component } from 'react'
// import { Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import { CookiesProvider, withCookies } from 'react-cookie';

import { Sessions } from './webApiClient';

import { durationHeavy } from './styles'
import AppWrapper     from './AppWrapper'
// import SvgFilters   from './styles/SvgFilters'
import Toolbar        from './Toolbar'
import Home           from './Home'
//import Gallery        from './Gallery'
import Blog           from './Blog'
import Community      from './Community'
//import LatestBuild    from './LatestBuild'
//import Pay            from './Pay'
// import UpdateBlog     from './UpdateBlog'
import UnknownRoute   from './UnknownRoute'
import ResetPassword from './ResetPassword'
//import SignupKey from './SignupKey'
import EmailVerified from './EmailVerified'
import PreviewBlog from './PreviewBlog'

import LoginPage from './account/LoginPage';
import ForgotPasswordPage from './account/ForgotPasswordPage';
import RegisterPage from './account/RegisterPage';
import VerifyPage from './account/VerifyPage';

import Subscription from './Subscription';

import ShopInfo from './ShopInfo';

// ---------------------------------------------
// const isAuthenticated = true

// const PrivateRoute = ({
//   // just renaming the component argument being passed in to be cAPS
//   component: Component,
//   ...rest
// }) =>
//   <Route {...rest} render={props =>
//     isAuthenticated
//       ? <Component {...props} />
//       : <Redirect to={{
//           pathname: '/',
//           // state: { from: props.location }
//         }} />
//   } />
// ---------------------------------------------

class App extends Component {
  static defaultProps = {

  }
  state = {
    isSignedIn: false,
  }

  render() {
    let SessionConnect = withCookies(props => Sessions.connectToCookies(props.cookies) || null);
  
    return (
      <CookiesProvider>
        <ShopInfo.Provider>
          <SessionConnect/>
          <Router>
            <Route render={({location, history, match}) =>
              <AppWrapper location={location}>
                {/* <SvgFilters /> */}
                <Toolbar currentPathname={location.pathname} />
                <TransitionGroup>
                  <CSSTransition 
                    key={location.key} 
                    timeout={{enter: durationHeavy * 2, exit: durationHeavy}}
                    classNames="transition">
                    <Switch key={location.key} location={location}>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/blog/2017/08/introducing-a-township-tale" component={() => window.location = 'https://www.townshiptale.com/blog/2017/08/28/introducing-a-township-tale'} />
                      <Route exact path="/blog/:year/:month/:day/:title" component={Blog} />
                      <Route exact path="/blog" component={Blog} />
                      <Route exact path="/previewblog" component={PreviewBlog} />
                      <Route exact path="/wiki" component={() => window.location = 'https://townshiptale.gamepedia.com'} />
                      <Route exact path="/discord" component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      <Route exact path="/community" component={Community} />
                      <Route exact path="/latest-build" component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      <Route exact path="/latestbuild" component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      <Route exact path="/latest_build" component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      <Route exact path="/download" component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      <Route exact path="/launcher" component={() => window.location = 'https://storage.googleapis.com/alta-launcher/alta-launcher/Alta%20Launcher%20Setup%20latest.exe'}/>
                      <Route exact path="/play" component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      {/*
                      <Route exact path="/pay" component={Pay} />*/}
                      <Route exact path='/key' component={() => window.location = 'https://discord.gg/ppEAy7D'} />
                      {/* <PrivateRoute exact path="/update-blog" component={UpdateBlog} /> 

                      <Route exact path="/cn/key" component={() => window.location = 'https://s3-us-west-1.amazonaws.com/alta-builds-usa/Launcher/Alta+Launcher+Setup.exe'}/>
  */}                    
                      <Route path="/supporter" component={Subscription}/>

                      <Route path="/account/register/:username" component={RegisterPage} />
                      <Route path="/account/register" component={RegisterPage} />
                      <Route path="/account/forgot-password" component={ForgotPasswordPage} />
                      <Route path="/account/login/:external" component={LoginPage} />
                      <Route path="/account/login" component={LoginPage} />
                      <Route path="/account/verify" component={VerifyPage} />
                      <Route path="/account/logout" render={props =>
                      {
                        Sessions.logout();

                        console.log("Logout");

                        return <div><Redirect to={'/'}/></div>
                      }}/>
                      
                      {/*Referenced externally, so can't move into /account */}
                      <Route exact path="/reset-password" component={ResetPassword} />
                      <Route exact path="/verify-email" component={EmailVerified} />
                      
                      <Route component={UnknownRoute} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </AppWrapper>
            } />
          </Router>
        </ShopInfo.Provider>
      </CookiesProvider>
    )
  }
}

export default App
