import React from 'react'
import styled from 'styled-components'
import {
  white2,
  yellow1,
  shadow6,
  shadow3,
  durationLight,
  easingMoveLight,
} from '../styles'

const Ul = styled.ul`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Li = styled.li`
  ${'' /* using to align width of btns with width to img + description */}
  margin: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Btn = styled.button`
  filter: ${shadow6};
  will-change: transform, filter;
  transition: all ${durationLight}ms ${easingMoveLight};

  &:hover:not(:disabled){
    filter: ${shadow3};
    transform: scale(1.05);
  }
`

const Img = styled.img`
  width: calc(3.5 * 48px);
  margin-bottom: 16px;
  border-radius: 50%;

  ${props => props.isGrey && 'filter: grayscale(100%)'};
`

const P = styled.p`
  color: ${props => props.isSelected ? yellow1 : white2};
  text-transform: capitalize;

  will-change: color;
  transition: color ${durationLight}ms ${easingMoveLight};
`

export default ({
  data = [],
  selectedName = 'blacksmith',
  handleClick = () => console.log('handleClick'),
}) =>
  <Ul>
    {data.map(role =>
      <Li key={role.name}>
        <Btn
          type="button"
          disabled={selectedName === role.name}
          onClick={handleClick.bind(this, role.name)}
        >
          <Img
            isGrey={selectedName !== role.name}
            src={role.icon}
            alt=""
          />
        </Btn>
        <P isSelected={selectedName === role.name}>{role.name}</P>
      </Li>
    )}
  </Ul>
