import React, {Component} from 'react'
import styled, {css} from 'styled-components'
import {
  DROP_DOWN_SELECT_COLOR,
  yellow1,
  DROP_DOWN_SELECT_SHADOW,
  DROP_DOWN_SELECT_Z_INDEX,
  // ICON_COLOR,
} from '../styles'

const BTN_HEIGHT = 40

const Svg = styled.svg`
  fill: ${yellow1};
  width: 40px;
`

const DownArrowIcon = () =>
  <Svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10l5 5 5-5z" />
  </Svg>

const UpArrowIcon = () =>
  <Svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14l5-5 5 5z" />
  </Svg>

// placeholder
const RelativeWrapper = styled.div`
  height: ${BTN_HEIGHT}px;
  position: relative;

  ${props => props.isExpand && css`
    z-index: ${DROP_DOWN_SELECT_Z_INDEX};
  `}
`

// prevents expanding list from displacing content
const AbsWrapper = styled.div`
  padding: 16px 24px;

  position: absolute;
  top: -16px;
  left: -24px;

  display: flex;

  ${props => props.isExpand && css`
    background-color: ${DROP_DOWN_SELECT_COLOR};
    filter: ${DROP_DOWN_SELECT_SHADOW};
  `}
`

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
`

const OptionBtn = styled.button`
  height: ${BTN_HEIGHT}px;
${'' /* vert. aligns text due to no descenders (txt is all caps) */}
  padding-top: 2px;

  text-transform: uppercase;

  ${props => props.isSelected && css`
    color: ${yellow1};
  `}
`

const ArrowBtn = styled.button`
  width: 40px;
  height: ${BTN_HEIGHT}px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export default class DropDownSelect extends Component {
  static defaultProps = {
    options: ['opt1', 'opt2'],
    selected: 'opt1',
    handleSelect: option => console.log(`handleSelect: ${option}`),
  }
  state = {
    isExpand: false,
  }

  handleOptionClick = evt => {
    this.props.handleSelect(evt)
    this.toggleExpand()
  }

  toggleExpand = () =>
    this.setState((prevState, props) => ({
      ...prevState,
      isExpand: !prevState.isExpand,
    }))

  render() {
    const {options, selected} = this.props
    const {isExpand} = this.state

    return (
      <RelativeWrapper isExpand={isExpand}>
        <AbsWrapper isExpand={isExpand}>
          <Ul>
            {isExpand
              ? options.map(opt =>
                  <li key={opt}>
                    <OptionBtn type="button" onClick={this.handleOptionClick} value={opt} isSelected={selected === opt}>{opt}</OptionBtn>
                  </li>
                )
              : <li key={selected}>
                  <OptionBtn type="button" onClick={this.toggleExpand} value={selected} isSelected>{selected}</OptionBtn>
                </li>
            }
          </Ul>
          <ArrowBtn type="button" onClick={this.toggleExpand}>
            {isExpand ? <UpArrowIcon /> : <DownArrowIcon />}
          </ArrowBtn>
        </AbsWrapper>
      </RelativeWrapper>
    )
  }
}
