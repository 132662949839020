// TODO remove exports from shadow values, export only specifically name shadows (e.g. BTN_SHADOW)
// TODO capitalize shadow names (e.g. shadow1 --> SHADOW_1)
// shadows follow the same pattern as headings: 6 is smallest, 1 is biggest
export const shadow1 = `
   drop-shadow(0 16px 24px rgba(0, 0, 0, 0.12))
   drop-shadow(0 6px 30px rgba(0, 0, 0, 0.14))
   drop-shadow(0 8px 10px rgba(0, 0, 0, 0.12))
   drop-shadow(0 -3px 4px rgba(0, 0, 0, 0.1))
`

export const shadow2 = `
   drop-shadow(0 8px 10px rgba(0, 0, 0, 0.12))
          drop-shadow(0 3px 14px rgba(0, 0, 0, 0.14))
          drop-shadow(0 5px 5px rgba(0, 0, 0, 0.12))
          drop-shadow(0 -2px 4px rgba(0, 0, 0, 0.1))
`

export const shadow3 = `
   drop-shadow(0 6px 10px rgba(0, 0, 0, 0.12))
          drop-shadow(0 1px 18px rgba(0, 0, 0, 0.14))
          drop-shadow(0 3px 5px rgba(0, 0, 0, 0.12))
          drop-shadow(0 -2px 3px rgba(0, 0, 0, 0.1))
`

export const shadow4 = `
   drop-shadow(0 4px 5px rgba(0, 0, 0, 0.12))
          drop-shadow(0 1px 10px rgba(0, 0, 0, 0.14))
          drop-shadow(0 2px 4px rgba(0, 0, 0, 0.12))
          drop-shadow(0 -1px 3px rgba(0, 0, 0, 0.1))
`

export const shadow5 = `
   drop-shadow(0 3px 4px rgba(0, 0, 0, 0.12))
          drop-shadow(0 3px 3px rgba(0, 0, 0, 0.14))
          drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12))
          drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1))
`

export const shadow6 = `
   drop-shadow(0 2px 2px rgba(0, 0, 0, 0.12))
          drop-shadow(0 3px 1px rgba(0, 0, 0, 0.14))
          drop-shadow(0 1px 5px rgba(0, 0, 0, 0.12))
          drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.1))
`

export const BTN_SHADOW = shadow6
export const BTN_HOVER_SHADOW = shadow3
export const BTN_ACTIVE_SHADOW = shadow6

export const DROP_DOWN_SELECT_SHADOW = shadow6
