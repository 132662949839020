/* eslint no-unused-vars: "off"*/
import { request as basicRequest, requestWithHeaders, HttpMethod } from './utils';

import jwt from "jsonwebtoken";

import Promise from 'bluebird';

// var executablePath = (process.env.NODE_ENV === 'development' || process.env.DEBUG_PROD === 'true') ?
// path.resolve(__dirname, "../../../AltaAPI/AltaAPI.exe") :
// path.resolve(process.resourcesPath, "./AltaAPI/AltaAPI.exe");

const productionEndpoint = "https://967phuchye.execute-api.ap-southeast-2.amazonaws.com/prod/api/";
const developmentEndpoint = "https://967phuchye.execute-api.ap-southeast-2.amazonaws.com/dev/api/";
// const localEndpoint = "http://localhost:13490/api/";
const localEndpoint = "https://localhost:44323/api/";

//Change here
const currentEndpoint = productionEndpoint;

const isUsingExternalApi = false;

let accessToken;
let refreshToken;
let identityToken;

let accessString;
let refreshString;
let identityString;
let cookies;

let headers =
{
    "Content-Type" : "application/json",
    'x-api-key': '2l6aQGoNes8EHb94qMhqQ5m2iaiOM9666oDTPORf'
};


function requestNoLogin(method, path, isCached, body)
{
    return new Promise((resolve, reject) =>
    {
        requestWithHeaders(method, headers, currentEndpoint + path, isCached, body)
        .then(resolve)
        .catch(reject);
    });
}

function request(method, path, isCached, body)
{
    return new Promise((resolve, reject) =>
    {
        updateTokens(() =>
        {
            requestWithHeaders(method   , headers, currentEndpoint + path, isCached, body)
            .then(resolve)
            .catch(reject);
        });
    });
}

function requestRefresh(method, path, isCached, body)
{
    return new Promise((resolve, reject) =>
    {
        requestWithHeaders(method, {...headers, Authorization:"Bearer " + refreshString}, currentEndpoint + path, isCached, body)
        .then(resolve)
        .catch(reject);
    });
}
    
function updateTokens(callback)
{
    if (!accessToken || accessToken.exp - (new Date().getTime() / 1000) < 15)
    {
        if (isUsingExternalApi)
        {
            basicRequest(HttpMethod.Get, 'account/tokens', false)
            .then(tokens =>
            {
                Sessions.setLocalTokens(tokens);
                callback();
            })
            .catch(error =>
            {
                console.log(error);
                console.log("ERROR: " + JSON.stringify(error)); 
            });
        }
        else
        {
            Sessions.refreshSession()
            .then(callback)
            .catch(e => console.error(e));
        }
    }
    else
    {
        callback();
    }
}

export const Sessions =
{
    getLoggedIn : () => !!accessToken,
    getVerified : () => !!accessToken && (accessToken.is_verified || accessToken.is_verified === "True"),
    getUsername : () => !!accessToken && accessToken.Username,
    getMember : () => !!accessToken && (accessToken.is_member || accessToken.is_member === "True"),
    getPolicy : policy => !!accessToken && accessToken.Policy.some(item => item === policy), 

    connectToCookies(providedCookies)
    {
        cookies = providedCookies;

        Sessions.setLocalTokens(
        {
            refresh_token : cookies.get("refresh_token"),
            access_token : cookies.get("access_token"),
            identity_token : cookies.get("identity_token"),
        });
    },

    setLocalTokens : (tokens, isRemembered) =>
    {
        if (!!tokens.access_token && accessString !== tokens.access_token)
        {
            accessString = tokens.access_token;

            headers.Authorization = "Bearer " + accessString;

            accessToken = jwt.decode(accessString);

            cookies.set("idsrv.session", "6fcdde0aaf8c19852bea81bb4e00ce96");
            cookies.set(".AspNetCore.altaid", "CfDJ8DVChDYYKj5Glgdv4-Ki9urvtv475OOanHD0s13aD-q4sMhwg3xQA0BoA7kUK0SbSDVNyKq_TQeCEbQyhEg4vUMw00f8nBafhXU75M2_7Cpc56GeTyjBTBJN9dYdpf0m_VyxJy-D1OIvMH_QgjI2XQLCXl_5Pj-In94dQGcb2czTJnJ3Gvb7xQiw6KvaUfifpk4oYKyUxoKdAJhc6E4F-0-h00LHJGBb45eJfEpPJtM2igNSbqfjRMds37qW-OqN15NRfZpymK2F37bo6EAOCBVwxtrb2Sj85YlIBKV0OD3hKbaaqeoxZv7W2xnwS_hmjF-A7zI3shf0GP3kd_iW381FapMQkTj6f5IdEnn3EzDnPi7NXoxC4anWq0uEAt_mrhHbEcBtyicK26c1JqnMP61B8GSodDxktS5BI88jPz7vANIYyz-LTF6fDhCfm_3HOxI61FsaI2aGJCz5GwxxNQmKy5ktT14c6a7gKC3LY-ugMc6KYi9OinNKtrbFi8EU0IubBBcvLa11gicivUeZLeudrLrudlVNE9eyoCl4Opx9rHi3jfvPz4epbMKUng7Y5w");
            
            cookies && cookies.set("access_token", accessString, { path: '/' });
        }

        if (!!tokens.refresh_token && refreshString !== tokens.refresh_token)
        {
            refreshString = tokens.refresh_token;

           refreshToken = jwt.decode(refreshString);


           if (isRemembered)
           {
               cookies && cookies.set("refresh_token", refreshString, { path: '/' });
           }
        }
        
        if (!!tokens.identity_token && identityString !== tokens.identity_token)
        {
            identityString = tokens.identity_token;

            identityToken = jwt.decode(identityString);
            
            cookies && cookies.set("identity_token", identityString, { path: '/' });
        }
    },

    logout: () =>
    {
        console.log("Logging out");

        if (!!cookies)
        {
            cookies.remove("refresh_token");
            cookies.remove("access_token");
            cookies.remove("identity_token");
        }

        identityString = null;
        refreshString = null;
        accessString = null;
        identityToken = null;
        refreshToken = null;
        accessToken = null;
    },

    loginWithUsername : (username, passwordHash, isRemembered, redirect) =>
    {
        return new Promise((resolve, reject) =>
        {
            requestNoLogin(HttpMethod.Post, 'sessions', false, {username, password_hash:passwordHash, redirect})
            .then(result => { Sessions.setLocalTokens(result, isRemembered); })
            .then(resolve)
            .catch(reject)
        });
    },
    
    loginWithEmail : (email, passwordHash, isRemembered, redirect) => 
    {
        return new Promise((resolve, reject) =>
        {
            requestNoLogin(HttpMethod.Post, 'sessions/email', false, {email, password_hash:passwordHash, redirect})
            .then(result => { Sessions.setLocalTokens(result, isRemembered); })
            .then(resolve)
            .catch(reject)
        });
    },

    refreshSession : () =>
    {
        return new Promise((resolve, reject) =>
        {
            requestRefresh(HttpMethod.Put, 'sessions')
            .then(result => Sessions.setLocalTokens(result))
            .then(resolve)
            .catch(reject)
        });
    },
}

export const Security =
{
    sso : () =>
    {
        return request(HttpMethod.Get, 'Security/sso');
    },
}

export const Launcher =
{
    getGames : () =>
    {
        console.error("TODO");
        return request(HttpMethod.Get, 'launcher/games');
    },
    
    getGameInfo : (gameId) =>
    {
        console.error("TODO");
        return request(HttpMethod.Get, `launcher/games/${gameId}`);
    },
}

export const Users =
{
    getInfo : (userId) =>
    {
        return request(HttpMethod.Get, `users/${userId}`);
    },
    
    register : (username, passwordHash, email, referral) =>
    {
        return requestNoLogin(HttpMethod.Post, 'users', false, {username, password_hash:passwordHash, email, referral});
    },
    
    getVerified : () =>
    {
        return new Promise((resolve, reject) =>
        {
            request(HttpMethod.Get, `users/${accessToken.UserId}/verification`)
            .then(result =>
            {
                if (result)
                {
                    accessToken.is_verified = true;
                }

                resolve(result);
            })
            .catch(reject);
        });
    },
    
    requestVerificationEmail : (email) =>
    {
        return request(HttpMethod.Put, `users/${accessToken.UserId}/verification`, false, {email});
    },
    
    verify : (token) =>
    {
        return request(HttpMethod.Post, `users/${accessToken.UserId}/verification`, false, {verification_token:token});
    },
    
    changePassword : (oldHash, newHash) =>
    {
        return request(HttpMethod.Put, `users/${accessToken.UserId}/password`, false, {old_password_hash:oldHash, new_password_hash:newHash});
    },
    
    resetPassword : (userId, newHash, token) =>
    {
        return request(HttpMethod.Post, `users/${userId}/password`, false, {reset_token:token, new_password_hash:newHash});
    },
    
    changeUsername : (username) =>
    {
        return request(HttpMethod.Put, `users/${accessToken.UserId}/username`, false, {new_username:username});
    },
}

export const Meta =
{
    //No applicable methods
}

export const Servers =
{
    //No applicable methods
}

export const Services =
{
    resetPassword : (email) =>
    {
        return request(HttpMethod.Put, `services/reset-password`, false, {email});
    },

    getTemporaryIdentity : (data) =>
    {
        return request(HttpMethod.Post, 'services/identity-token', false, {user_data:data});
    }
}

export const Shop =
{
    getSandbox : () =>
    {
        return request(HttpMethod.Get, 'shop/sandbox');
        // {
        //     "sandbox": true
        // }
    },

    Account : 
    {
        getProfile : () =>
        {
            return request(HttpMethod.Get, 'shop/account');
            // {
            //     "shard_balance": 0,
            //     "wallet_amount": 0,
            //     "wallet_currency": "string",
            //     "subscription_status": 
            //      {
            //       "is_member": true,
            //       "member_end_date": "2019-01-31T03:48:29.902Z",
            //       "is_renewing": true
            //      }
            //   }
        },
        
        getPaymentMethods : () =>
        {
            return request(HttpMethod.Get, 'shop/account/payments');
            // [
            //     {
            //       "type": "paypal",
            //       "id": 0,
            //       "name": "string",
            //       "payment_system": 
            //       {
            //         "id": 0,
            //         "name": "string"
            //       }
            //     }
            // ]
        },
        
        getItems : () =>
        {
            return request(HttpMethod.Get, 'shop/account/items');
            // [
            //     {
            //       "image_url": "string",
            //       "quantity": 0,
            //       "id": 0,
            //       "sku": "string",
            //       "localized_name": "string",
            //       "enabled": true,
            //       "advertisement_type": "recommended",
            //       "virtual_currency_price": 0
            //     }
            // ]
        },
        
        getRewards : () =>
        {
            return request(HttpMethod.Get, 'shop/account/rewards');
            // {
            //     "comment": "string",
            //     "rewards": 
            //      [
            //       {
            //         "type": "string",
            //         "amount": 0
            //       }
            //     ],
            //     "reward_periods_passed": 0
            // }
        },
        
        getSubscription : () =>
        {
            return request(HttpMethod.Get, 'shop/account/subscription');
            // {
            //     "id": 0,
            //     "plan": {
            //       "id": 0,
            //       "external_id": "string",
            //       "group_id": "string",
            //       "project_id": "string",
            //       "name": {
            //         "en": "string"
            //       },
            //       "description": {
            //         "en": "string"
            //       },
            //       "localized_name": "string",
            //       "charge": {
            //         "amount": 0,
            //         "currency": "string",
            //         "period": {
            //           "value": 0,
            //           "type": "string"
            //         }
            //       },
            //       "expiration": {
            //         "value": 0,
            //         "type": "string"
            //       },
            //       "trial": {
            //         "value": 0,
            //         "type": "string"
            //       },
            //       "grace_period": {
            //         "value": 0,
            //         "type": "string"
            //       },
            //       "type": "string",
            //       "tags": [
            //         "string"
            //       ],
            //       "status": {
            //         "value": "disabled",
            //         "counters": {
            //           "active": 0,
            //           "canceled": 0,
            //           "expired": 0,
            //           "frozen": 0
            //         }
            //       }
            //     },
            //     "user": {
            //       "id": "string",
            //       "name": "string"
            //     },
            //     "product": {},
            //     "charge_amount": 0,
            //     "currency": "string",
            //     "date_create": "2019-01-31T03:51:29.364Z",
            //     "date_end": "2019-01-31T03:51:29.364Z",
            //     "date_next_charge": "2019-01-31T03:51:29.364Z",
            //     "date_last_charge": "2019-01-31T03:51:29.364Z",
            //     "status": "active",
            //     "comment": "string"
            // }
        },
        
        cancelSubscription : () =>
        {
            return request(HttpMethod.Delete, 'shop/account/subscription');
        },
    },

    Debug :
    {
        modifyBalance : (change) =>
        {
            return request(HttpMethod.Post, 'shop/debug/balance', false, {change});
        },

        deleteMembership : () =>
        {
            return request(HttpMethod.Delete, 'shop/debug/membership');
        },

        clearItems : () =>
        {
            return request(HttpMethod.Delete, 'shop/debug/inventory');
        },
    },

    Categories :
    {
        //Unused
    },

    Items :
    {
        getItems : () =>
        {
            return request(HttpMethod.Get, 'shop/items');
            // [
            //     {
            //       "prices": {
            //         "USD": 0
            //       },
            //       "default_currency": "string",
            //       "permanent": true,
            //       "id": 0,
            //       "sku": "string",
            //       "localized_name": "string",
            //       "enabled": true,
            //       "advertisement_type": "recommended",
            //       "virtual_currency_price": 0
            //     }
            // ]
        },
        
        getInfo : (itemId) =>
        {
            return request(HttpMethod.Get, `shop/items/${itemId}`);
            // {
            //     "item_code": "string",
            //     "name": {
            //       "en": "string"
            //     },
            //     "description": {
            //       "en": "string"
            //     },
            //     "long_description": {
            //       "en": "string"
            //     },
            //     "image_url": "string",
            //     "item_type": "Consumable",
            //     "expiration": 0,
            //     "purchase_limit": 0,
            //     "keywords": {
            //       "en": [
            //         "string"
            //       ]
            //     },
            //     "groups": [
            //       0
            //     ],
            //     "deleted": true,
            //     "prices": {
            //       "USD": 0
            //     },
            //     "default_currency": "string",
            //     "permanent": true,
            //     "id": 0,
            //     "sku": "string",
            //     "localized_name": "string",
            //     "enabled": true,
            //     "advertisement_type": "recommended",
            //     "virtual_currency_price": 0
            // }
        },
    },

    Sets :
    {        
        getSet : (sku) =>
        {
            return request(HttpMethod.Get, `shop/sets/${sku}`);
            // {
            //     "sku": "string",
            //     "items": [
            //       {
            //         "identifier": 0,
            //         "sku": "string"
            //       }
            //     ]
            // }
        },
    },

    Purchase :
    {     
        Shards :
        {
            buyItem : (itemId) =>
            {
                return request(HttpMethod.Post, 'shop/purchase/shards/items');
            },
        },

        Premium :
        {
            buyItem : (itemId) =>
            {
                return request(HttpMethod.Post, 'shop/purchase/premium/items', false, {item_id:itemId});
                // {
                //     "token": "string"
                // }
            },
            
            buySubscription : (subscriptionId) =>
            {
                return request(HttpMethod.Post, 'shop/purchase/premium/subscriptions', false, {plan_external_id:subscriptionId});
                // {
                //     "token": "string"
                // }
            },
            
            buyShards : (shardsId) =>
            {
                return request(HttpMethod.Post, 'shop/purchase/premium/shards', false, {shards_package_id:shardsId});
                // {
                //     "token": "string"
                // }
            },
        }
    },

    Subscriptions :
    {     
        getSubscriptions : () =>
        {
            return requestNoLogin(HttpMethod.Get, 'shop/subscriptions');
            // [
            //     {
            //       "id": 0,
            //       "external_id": "string",
            //       "group_id": "string",
            //       "project_id": "string",
            //       "name": {
            //         "en": "string"
            //       },
            //       "description": {
            //         "en": "string"
            //       },
            //       "localized_name": "string",
            //       "charge": {
            //         "amount": 0,
            //         "currency": "string",
            //         "period": {
            //           "value": 0,
            //           "type": "string"
            //         }
            //       },
            //       "expiration": {
            //         "value": 0,
            //         "type": "string"
            //       },
            //       "trial": {
            //         "value": 0,
            //         "type": "string"
            //       },
            //       "grace_period": {
            //         "value": 0,
            //         "type": "string"
            //       },
            //       "type": "string",
            //       "tags": [
            //         "string"
            //       ],
            //       "status": {
            //         "value": "disabled",
            //         "counters": {
            //           "active": 0,
            //           "canceled": 0,
            //           "expired": 0,
            //           "frozen": 0
            //         }
            //       }
            //     }
            // ]
        },
    },

    Shards :
    {     
        getPackages : () =>
        {
            return request(HttpMethod.Get, 'shop/shards');
            // {
            //     "id": 0,
            //     "vc_name": {
            //       "en": "string"
            //     },
            //     "base": {
            //       "USD": 0
            //     },
            //     "default_currency": "string",
            //     "min": 0,
            //     "max": 0,
            //     "is_currency_discrete": true,
            //     "allow_user_sum": true,
            //     "packets": {
            //       "additionalProp1": [
            //         {
            //           "amount": 0,
            //           "id": 0,
            //           "price": 0,
            //           "image_url": "string",
            //           "sku": "string",
            //           "description": {
            //             "en": "string"
            //           },
            //           "bonus": 0,
            //           "enabled": true
            //         }
            //       ],
            //       "additionalProp2": [
            //         {
            //           "amount": 0,
            //           "id": 0,
            //           "price": 0,
            //           "image_url": "string",
            //           "sku": "string",
            //           "description": {
            //             "en": "string"
            //           },
            //           "bonus": 0,
            //           "enabled": true
            //         }
            //       ],
            //       "additionalProp3": [
            //         {
            //           "amount": 0,
            //           "id": 0,
            //           "price": 0,
            //           "image_url": "string",
            //           "sku": "string",
            //           "description": {
            //             "en": "string"
            //           },
            //           "bonus": 0,
            //           "enabled": true
            //         }
            //       ]
            //     },
            //     "type": "string",
            //     "image_url": "string"
            // }
        },
    },
}