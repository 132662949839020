import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
  width: 100%;
  margin-bottom: 96px;
  padding: 0 8px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ({
  children = 'WrapperChild'
}) =>
  <Section>
    {children}
  </Section>
