import styled from 'styled-components'
import {
  BTN_TXT_COLOR,
  BTN_SHADOW,
  BTN_HOVER_SHADOW,
  BTN_ACTIVE_SHADOW,
  BTN_HOVER_SCALE,
  BTN_ACTIVE_SCALE,
  BTN_DURATION,
  BTN_EASING,
} from '../styles'
import signUpBtnBackground from './imgs/signUpBtnBackground.svg'

export default styled.button`
  width: calc(9 * 48px);
${'' /* btn is centered, 16px acts as margin ethier side on small displays */}
  max-width: calc(100% - 16px);
  height: calc(2.8 * 48px);

  position: absolute;
  bottom: 0;
  left: 50%;

  text-align: center;
  transform: translate3d(-50%, 0, 0);

  background-image: url(${signUpBtnBackground});
  background-repeat: no-repeat;
  filter: ${BTN_SHADOW};

  &:hover{
    filter: ${BTN_HOVER_SHADOW};
    transform: translate3d(-50%, 0, 0) scale(${BTN_HOVER_SCALE});
  }

  &:active{
    filter: ${BTN_ACTIVE_SHADOW};
    transform: translate3d(-50%, 0, 0) scale(${BTN_ACTIVE_SCALE});
  }

  will-change: filter, transform;
  transition: all ${BTN_DURATION}ms ${BTN_EASING};

  span {
    position: relative;
    top: -12px;

    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${BTN_TXT_COLOR};

    @media (max-width: 405px) {
      top: -24px;
      font-size: 30px;
    }
  }
`
