import React from 'react'
import styled from 'styled-components'
import {
  shadow6,
} from '../styles'

const Img = styled.img`
  margin-bottom: 40px;
  filter: ${shadow6};
`

export default ({
  src,
  alt,
}) =>
  <Img
    src={src}
    alt={alt}
  />
