import React from 'react'
import styled from 'styled-components'

const P = styled.p`
  margin-bottom: 24px;
  text-align: justify;
`

export default () =>
  <P>Join our community on social media! It's a great chance to meet other players, share your experiences, keep up-to-date, and discover new things to do with other settlers!</P>
