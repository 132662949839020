import React from 'react'
import { withRouter } from 'react-router-dom'
import { Btn } from './'

const handleClick = ({
  to = '/',
  history = {}
} = {}) =>
  history.push(to)

const NavBtn = ({
  to = '/',
  history,
  children = <div>NavBtnChild</div>
}) =>
  <Btn handleClick={handleClick.bind(this, { to, history })}>
    {children}
  </Btn>

export default withRouter(NavBtn)
