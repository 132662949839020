import React from 'react'
import {
  Heading,
  Loader,
  RouteLink,
} from '../Common'
import Wrapper from './Wrapper'
import BlogPost from '../BlogPost'
import ErrMsg from './ErrMsg'

export default ({
  isAwaitingPost = true,
  blogPost = null,
  handleLinkClick = () => console.log('linkClick'),
  serverErr,
}) => {
  const isShowBlogPost = !!blogPost
  const isShowLoader = isAwaitingPost && !!serverErr
  const isShowErr = !!serverErr
  const isShowLink = !isShowErr && !isAwaitingPost

  return (
    <Wrapper>
      <Heading txt="Blog" />
      {isShowBlogPost && <BlogPost { ...blogPost } isHideShareBtns />}
      {isShowLoader && <Loader />}
      {isShowErr && <ErrMsg>{serverErr}</ErrMsg>}
      {isShowLink &&
        <RouteLink
          onClick={handleLinkClick}
          to='/blog'
        >
          ...continue
        </RouteLink>
      }
    </Wrapper>
  )
}
