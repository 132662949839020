import React from 'react'
import styled from 'styled-components'
import {
  googleAnalytics,
} from '../util'
import {
  durationLight,
  easingMoveLight,
  shadow4,
} from '../styles'
import {
  twitter,
  alta,
  facebook,
} from '../imgs'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const A = styled.a`
  margin: 16px;
`

// not using <CliackableImg /> to remove shadows on non-hover state
const Img = styled.img`
  width: ${props => props.size};
  ${props => props.isGray && 'filter: grayscale(100%)'};

  &:hover{
    filter: ${shadow4} grayscale(0%);
    transform: scale(1.05);
  }

  will-change: filter, transform;
  transition: all ${durationLight}ms ${easingMoveLight};
`

export default () =>
  <Wrapper>
    <A
      onClick={googleAnalytics.bind(this, { evtCat: '/Footer/twitterLink' })}
      href="https://twitter.com/townshiptale"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img
        src={twitter}
        alt="twitter"
        size="32px"
        isGray
      />
    </A>
    <A
      onClick={googleAnalytics.bind(this, { evtCat: '/Footer/altaLink' })}
      href="http://altavr.io"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img
        src={alta}
        alt="alta"
        size="96px"
      />
    </A>
    <A
      onClick={googleAnalytics.bind(this, { evtCat: '/Footer/facebookLink' })}
      href="https://www.facebook.com/townshiptale"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img
        src={facebook}
        alt="facebook"
        size="32px"
        isGray
      />
    </A>
  </Wrapper>
