import styled from 'styled-components'

export default styled.img`
  width: 100%;
  max-width: calc(12 * 48px);

  position: absolute;
  top: calc(2 * 48px);
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  display: block;
`
