import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  width: 100%;
  max-width: ${props => props.size};
  justify-content: ${props => props.justify};

  display: flex;
  flex-direction: row;
  align-items: center;
`

export default ({
  children = <div>Row Child</div>,
  size = '100%',
  justify = 'space-around'
}) =>
  <Row size={size} justify={justify}>{children}</Row>
