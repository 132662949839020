// @flow
import React, { Component } from 'react';

import ShopInfo from '../ShopInfo';

// import AutoScale  from 'react-auto-scale';

import { Shop, Sessions } from '../webApiClient';

import Loader from '../Common/Loader';
import Popup from '../Common/Popup';

import { Page, Wrapper, PerkList, SupportBoard, Disclaimer, Offers, OfferButton, SupporterPanel, PanelText, CancelButton, RenewButton } from './styles';


class SubscriptionPage extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {};
    }
     
    componentDidMount()
    {
        var layoutRequest = new XMLHttpRequest();

        layoutRequest.addEventListener("load", () => 
        {
            try
            {
                console.log(layoutRequest.responseText);
                var perks = JSON.parse(layoutRequest.responseText);
            }
            catch (e)
            {
                console.log(e);
            }
            
            this.setState({perks});
        });

        layoutRequest.open("GET", "https://storage.googleapis.com/alta-launcher/shop/supporter-perks.json");
        layoutRequest.send();
    }
     
    showCheckout(id)
    {
        Shop.Purchase.Premium.buySubscription(id)
        .then(response =>
        {   
            this.props.context.showCheckout(response.token);
        });
    }

    beginRenew()
    {
        this.setState({isBeginRenew: true});
    }

    checkCancel()
    {
        this.setState({isCancelling:true, isBeginRenew: false});
    }

    cancelCancel()
    {
        this.setState({isCancelling:false});
    }

    confirmCancel()
    {        
        Shop.Account.cancelSubscription();

        this.setState({isCancelling:false, cancelDelayWarning:true});

        this.props.context.refreshShopMultiple();
    }    

    hideDelayWarning()
    {
        this.setState({cancelDelayWarning:false});
    }

    getExpirationDate()
    {
        var date = new Date(this.props.context.profile.subscription_status.member_end_date);

        return date.toLocaleDateString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'
          }).replace(/ /g, '-');
    }  

    render()
    {
        const { profile, subscriptions } = this.props.context;
        
        var isLoggedIn = Sessions.getLoggedIn() && Sessions.getVerified();

        if ((isLoggedIn && !profile) || !subscriptions)
        {
            return <Loader/>;
        }

        var highest = undefined;

        var content = (
        <Wrapper>
            <SupportBoard>
                    <PerkList items={this.state.perks}/>
            </SupportBoard>
            {/*<CancelledMessage/>*/}
            {isLoggedIn && profile.subscription_status.is_member && (profile.subscription_status.is_renewing || !this.state.isBeginRenew) ? 
            <SupporterPanel>
                {profile.subscription_status.is_renewing ? 
                [<PanelText>Hey there Supporter! Thank you for your ongoing support.
                    <br/>Link your Discord account in settings to get your Discord role!
                    <br/>More perks will come with time. We're working on them now!
                    <br/>If you have futher questions, hit us up in Discord.</PanelText>,
                <CancelButton onClick={this.checkCancel.bind(this)}>Cancel Support</CancelButton>] :
                [<PanelText>Hey there! Thank you for your time supporting.
                    <br/>Your renewal has been cancelled.
                    <br/>To keep the perks after {this.getExpirationDate()}, be sure to renew!
                    <br/>If you have futher questions, hit us up in Discord.</PanelText>,
                <RenewButton onClick={this.beginRenew.bind(this)}>Renew Support</RenewButton>]}
            </SupporterPanel>
            :
            <Offers>
                {subscriptions.sort((a, b) => b.charge.amount / b.charge.period.value - a.charge.amount / a.charge.period.value).map(item => 
                {
                    var price = Math.round(item.charge.amount / item.charge.period.value * 100) / 100;
                    var saving = null;

                    if (!highest)
                    {
                        highest = price;
                    }
                    else
                    {
                        saving = Math.round((1 - (price / highest)) * 1000) / 10;
                    }

                    var clicked = isLoggedIn ? () => this.showCheckout.bind(this)(item.external_id) : (() => { this.props.history.push('/account/login');}).bind(this);

                    return <OfferButton key={item.name} title={item.name} saving={saving} monthly={price} footer={item.description} onClick={clicked}/>;
                })}
            </Offers>}
        </Wrapper>);

        return [
            <Page>{content}</Page>, 
            (this.state.isCancelling ? <Popup title={"Stop Renewal?"} content={"Do you want to stop renewing your support?"} buttonA={"Yes"} buttonB={"No"} aClicked={this.confirmCancel.bind(this)} bClicked={this.cancelCancel.bind(this)}/> : null),
            (this.state.cancelDelayWarning ? <Popup title={"Note"} content={"Cancellation may take a minute or two to process."} buttonA={"Ok"} aClicked={this.hideDelayWarning.bind(this)}/> : null),
            <Disclaimer>To ensure things are fair, if any ongoing perks are ever to be reduced or removed, we will let you know in advance, via email, with an opportunity to cancel and refund your remaining subscription.</Disclaimer>
        ];
    }
}

export default ShopInfo.wrap(SubscriptionPage);