export default function ({
  type = 'event',
  evtCat = 'evtCat',
  evtAction = 'click'
} = {}) {
  const { ga } = window
  // pageview is no longer required as index.html <header/> fires evt
  type === 'pageview'
    ? ga('send', {
        hitType: 'pageview',
        page: window.location.pathname
      })
    : ga('send', {
          hitType: type,
          eventCategory: evtCat,
          eventAction: evtAction
        }
      )
}
