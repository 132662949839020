import React from 'react'
import styled from 'styled-components'

const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ({
  children = <div>Form Child</div>
}) =>
  <Form>{children}</Form>
