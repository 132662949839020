import React from 'react'
import {
  RouteWrapper,
  Heading,
  Loader,
} from '../Common'
import Li from './Li'
import BlogPost from '../BlogPost'
import ErrMsg from './ErrMsg'

export default ({
  isAwaitingInitialPost = true,
  isAwaitingNextPost = false,
  blogPosts = [],
  serverErr,
  handleUrlClick = () => console.log('handleUrlClick'),
}) => {
  const isServerErr = !!serverErr

  return (
    <RouteWrapper>
      <Heading txt="Development Blog" />
      <ul>
        {blogPosts.filter(post => post.isPublic)
          .map(post =>
            <Li key={post.enteredAt}>
              <BlogPost { ...post } />
            </Li>
        )}
      </ul>
      {(isAwaitingInitialPost || isAwaitingNextPost)
        && !isServerErr
        && <Loader />
      }
      {isServerErr && <ErrMsg>{serverErr}</ErrMsg>}
    </RouteWrapper>
  )
}
