import React from 'react'
import styled from 'styled-components'
import {
  TOOLBAR_FOOTER_IMG_HEIGHT,
  brown2,
  brown1,
} from '../styles'

const Svg = styled.svg`
  width: 100%;
  height: ${TOOLBAR_FOOTER_IMG_HEIGHT}px;

  position: absolute;
  bottom: 3px;
  left: 0;
`

const Thickness = styled.polygon`
  fill: ${brown2};
`

const TopLayer = styled.polygon`
  fill: ${brown1};
`

export default () =>
  <Svg
    preserveAspectRatio="none"
    viewBox="0 0 2000 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Thickness points="2000 12 1921.48 10.19 1786.52 10.64 1504.59 9.73 1433.61 10.64 1389.03 6.55 1301.1 8.35 1180.68 9.28 1105.7 12 1036.16 7.87 931.75 12 849.77 10.19 808.63 10.19 705.81 7.02 620.83 12 468.87 7.92 262.21 11.02 147.96 4.3 90.98 7.47 30.99 8.38 0 12 0 2 2000 2 2000 12"/>
    <TopLayer points="2000 8 1921.48 6.55 1786.52 6.91 1504.59 6.19 1433.61 6.91 1389.03 3.64 1301.1 5.08 1180.68 5.83 1105.7 8 1036.16 4.7 931.75 8 849.77 6.55 808.63 6.55 705.81 4.01 620.83 8 468.87 4.74 262.21 7.22 147.96 1.84 90.98 4.38 30.99 5.1 0 8 0 0 2000 0 2000 8"/>
  </Svg>
