import React from 'react'
import Article from './Article'
import Header from './Header'
import H3 from './H3'
import P from './P'
import Img from './Img'
import ShareBtns from './ShareBtns'
import ExternalLink from './ExternalLink'
import Video from './Video'
import Compare from './Compare'
import genPostUrl from './genPostUrl'

var is1stImg = true

export default ({
  title = 'title',
  author = 'author',
  enteredAt = 1234567890,
  content = [],
  isHideShareBtns = false,
  isShowUrl = false,
  handleUrlClick = () => console.log('handleUrlClick'),
  handleShareBtnClick = () => console.log('handleShareBtnClick'),
}) =>
{
  let url = genPostUrl({ timestamp: enteredAt, title });

  return (
  <Article>
    <Header
      title={title}
      author={author}
      url={url}
      enteredAt={enteredAt}
    />

    {content.map((item, i) => {
      switch (item.type) {
        case 'paragraph1':
          return <P itemprop="description" key={i}>{item.val}</P>

        case 'img':
          if (is1stImg) {
            is1stImg = false
            return (
              <Img
                itemprop="image"
                key={i}
                src={item.val}
                alt={item.alt}
              />
            )

          } else {
            return (
              <Img
                key={i}
                src={item.val}
                alt={item.alt}
              />
            )

          }

        case 'subHeading':
          return (
            <H3 key={i}>{item.val}</H3>
          )

        case 'paragraph':
          return (
            <P key={i}>
              {item.val.map((entry, i) => {
                switch (entry.type) {
                  case 'txt':
                    return <span key={`${i}b`}>{entry.val}&nbsp;</span>

                  case 'link':
                    return (
                      <ExternalLink
                        key={`${i}b`}
                        url={entry.url}
                      >
                        {entry.txt}
                      </ExternalLink>
                    )

                  default:
                    throw Error('Unkonwn item type')
                }
              })}
            </P>
          )

        case 'video':
          return (
            <Video key={i} width="560" height="315" src={item.val} frameBorder="0" allowFullScreen></Video>
          )

        case 'compare':
        console.log("compare");
          return (
            <Compare key={i} left={item.left} right={item.right} alt={item.alt}/>
          )

        default:
          console.log(item.type);
          return (<div>Unknown item type: {item.type}</div>);
      }
    })}

    {!isHideShareBtns &&
      <ShareBtns
        title={title}
        url={url}
        isShowUrl={isShowUrl}
        handleUrlClick={handleUrlClick}
        handleShareBtnClick={handleShareBtnClick}
      />
    }

  </Article>);
}
