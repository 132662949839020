import React, { Component } from 'react'

import queryString from 'query-string';

import View from './View'
import { VerifyUser } from '../api/index';

import ShopInfo from '../ShopInfo';

class EmailVerified extends Component 
{
  static defaultProps = {}
  state = 
  {
    isAwaitingResponse: true,
    isSuccess: false,
    serverErr: "",
  }

  componentDidMount()
  {
    var { userId, token } = queryString.parse(this.props.location.search);

    console.log('validating for ' + userId + ' ' + token);

    this.setState({isAwaitingResponse:true});

    VerifyUser(userId, token, (isOk, body) =>
    {
      if (isOk)
      {
        this.props.context.fullRefresh();

        this.timeout = setTimeout(() => this.props.history.push('/account/login'), 2000);
      }

      if (body.message === "User is already verified")
      {
        this.timeout = setTimeout(() => this.props.history.push('/account/login'), 2000);
      }

      this.setState(
      {
        isAwaitingResponse: false,
        isSuccess: isOk,
        serverErr: isOk ? undefined : body.message,
      });
    },
    error =>
    {
      this.setState(
      {
        isAwaitingResponse: false,
        serverErr: error.message
      });
    });
  }

  onComponentDidUnmount()
  {
    if (!!this.timeout)
    {
      clearTimeout(this.timeout);
    }
  }

  render() 
  {
    return (
      <View
        {...this.props}
        {...this.state}
      />
    )
  }
}

export default ShopInfo.wrap(EmailVerified);