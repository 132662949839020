/*
  differences from ./Common/RouteWrapper
    • no left / right padding (to allow for children backgrounds)
    • padding top is reduced for banner image
*/

import styled from 'styled-components'
import {
  routeTransition,
  TOOLBAR_LINK_HEIGHT,
} from '../styles'
import { FOOTER_HEIGHT  } from '../Footer/Wrapper'

export default styled.section`
  width: 100%;
  min-height: 100vh;

  position: absolute;
  top: 0;
  left: 0;

  padding: ${TOOLBAR_LINK_HEIGHT}px 0px ${FOOTER_HEIGHT + 48}px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${routeTransition()}
`
