var requestPromise = require('request-promise');

export const HttpMethod = 
{
    Get : 'GET',
    Post : 'POST',
    Put : 'PUT',
    Patch : 'PATCH',
    Delete : 'DELETE'
}

const defaultHeaders =
{
    "Content-Type" : "application/json",
};

export function request(method, urlEnd, isCached, body)
{
    return requestWithHeaders(method, defaultHeaders, urlEnd, isCached, body);
}

export function requestWithHeaders(method, headers, urlEnd, isCached, body)
{
    let url = urlEnd.startsWith('http') ? urlEnd : 'http://localhost:5000/api/' + urlEnd;

    if (body)
    {
        body = JSON.stringify(body);
    }

    var options =
    {
        url,
        method,
        headers,
        body,
        resolveWithFullResponse: true
    }
    
    var promise = requestPromise(options);

    var returning = promise.then(result =>
    {
        try
        {            
            return result.body && JSON.parse(result.body);
        }
        catch (e)
        {
            console.log(result);
            throw e;
        }
    });

    returning.abort = () =>
    {
        promise.abort();
    }

    return returning;
}