import styled from 'styled-components'

export default styled.section`
  width: 100%;
${'' /* 40px for toolbar */}
  height: calc(100vh - 40px);
  margin-bottom: 48px;
  overflow: hidden;

  position: relative;
`
