import React from 'react'
import styled from 'styled-components'
import {
  CLICKABLE_COLOR,
  durationLight,
  easingMoveLight,
} from '../styles'

const A = styled.a`
  ${'' /* required for transform */}
  display: inline-block;
  text-transform: uppercase;

  &:hover{
    transform: scale(1.05);
  }

  color: ${CLICKABLE_COLOR};
  will-change: transform;
  transition: transform ${durationLight}ms ${easingMoveLight};
`

export default ({
  url = 'https://www.google.com',
  children = 'ExternalLinkChild',
}) =>
  <A
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </A>
