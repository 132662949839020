import React from 'react'
import styled from 'styled-components'
import {
  durationHeavy,
  easingMoveHeavy,
} from '../styles'
import { Icon } from '../Common'

const Btn = styled.button`
  width: 48px;
  height: 100%;

  position: absolute;
  top: 0;
  ${props => props.isBack
    ? 'left: 0'
    : 'right: 0'
  };

  background: hsla(0, 0%, 0%, 0.54);
  opacity: 0.34;

  &:hover{
    opacity: 1;
  }

  will-change: opacity;
  transition: opacity ${durationHeavy}ms ${easingMoveHeavy};
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({
  isBack = false,
  handleClick = () => console.log('handleClick'),
  isDisable = false
}) => {
  const iconCat = isBack
    ? 'back'
    : 'forward'

  return (
    <Btn
      isBack={isBack}
      onClick={handleClick}
      disabled={isDisable}
    >
      <Content>
        <Icon cat={iconCat} />
      </Content>
    </Btn>
  )
}
