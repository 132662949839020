// @flow
/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react';

import sha512 from 'crypto-js/sha512'

import { Sessions, Users, Services } from '../../webApiClient';

import {
    RouteWrapper,
    Heading,
    Form,
    TxtInput,
    Submit,
    Icon,
    SmallLink,
    Row
  } from '../../Common'
  
import {
    isName,
    isEmail,
    isPassword,
  } from '../../util'


export default class ForgotPasswordPage extends Component
{
    static defaultProps = 
    {
        
    }

    state = 
    {
        isDone: false,
        isValid: false,

        username:
        {
            name:"username",
            value:"",
            error:"",
            isFocused:false,
            isDisabled:false,
        },
        
        email:
        {
            name:"email",
            value:"",
            error:"",
            isFocused:false,
            isDisabled:false,
        }
    }

    componentWillMount()
    {        

    }

    componentWillReceiveProps(nextProps)
    {

    }

    componentWillUnmount()
    {

    }

    handleFocus = evt => 
    {
        const { name } = evt.target
    
        this.setState((prevState, props) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            isFocused: true,
          }
        }))
      }

    handleUsername = evt =>
    {
        const { name, value } = evt.target
        
        var newState = {};

        var error = isName(value);

        newState.isValid = !error;

        if (value === "")
        {
            error = null;
        }

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: error
        };

        newState.email =
        {
            ...this.state.email,
            isDisabled: value !== "",
        }

        this.setState(newState);
    }
      
    handleEmail = evt =>
    {
        const { name, value } = evt.target
        
        var newState = {};

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: null
        };
        
        newState.username =
        {
            ...this.state.username,
            isDisabled: value !== "",
        }

        newState.isValid = isEmail(value);
        
        if (!newState.isValid && value !== 0)
        {
            newState[name].error = "This is not a valid email";
        }

        this.setState(newState);
    }

    handleBlur = evt =>
    {
        const { name } = evt.target

        this.setState((prevState, props) => ({
        ...prevState,
        [name]: {
            ...prevState[name],
            isFocused: false,
        }
        }))
    }

    handleBack(evt)
    {
        evt.preventDefault();

        this.props.history.push("/account/login");
    }

    handleForgot(evt)
    {
        evt.preventDefault();

        // if (this.state.username.value != "")
        // {

        // }
        // else
        if (!isEmail(this.state.email.value))
        {
            return;
        }

        Services.resetPassword(this.state.email.value)
        .then(data =>
        {
            console.log(data);
        });

        this.setState({isDone: true});
    }

    render()
    {
        if (this.state.isDone)
        {
            return (
                <RouteWrapper>
                    <Heading txt={`If this account exists, an email will be sent with further instructions.`} />
                    <Form>
                        <Row size='calc(9 * 48px)' justify='space-around'>
                            <Submit handleSubmit={this.handleBack.bind(this)}>Go to Login</Submit>
                        </Row>
                    </Form>
                </RouteWrapper>);
        }
        else
        {
            return (
                <RouteWrapper>
                    <Heading txt={`Reset Password`} />
                    <Form>
                       <TxtInput {...this.state.email} handleFocus={this.handleFocus} handleInput={this.handleEmail.bind(this)} handleBlur={this.handleBlur}/>
                    
                        <Row size='calc(9 * 48px)' justify='space-around'>
                            <Submit type={'button'} handleSubmit={this.handleBack.bind(this)}>Back</Submit>
                            <Submit handleSubmit={this.handleForgot.bind(this)} disabled={this.state.isProcessing || !this.state.isValid}>Send Code</Submit>
                        </Row>
                    </Form>
                </RouteWrapper>);
        }
    }
}