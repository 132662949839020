import styled from 'styled-components'
import {
  yellow1,
} from '../styles'

export default styled.h3`
  margin-bottom: 16px;

  text-transform: capitalize;
  align-self: flex-start;
  color: ${yellow1};
  font-size: 28px;
  font-weight: 700;
`
