import React from 'react'
import styled from 'styled-components'
import { brown7 } from '../styles'

export const FOOTER_HEIGHT = 3 * 48

const Footer = styled.footer`
  width: 100%;
  height: ${FOOTER_HEIGHT}px;

  position: absolute;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  background: ${brown7};
`

export default ({
  children = <div>child</div>
}) =>
  <Footer>{children}</Footer>
