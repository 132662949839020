import React from 'react'
import { css } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import {
  durationHeavy,
  easingEnterHeavy,
  easingExitHeavy
} from '../styles'

export const fadeTransitionStyles = css`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity ${durationHeavy}ms ${easingEnterHeavy};
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity ${durationHeavy}ms ${easingExitHeavy};
  }
`

export default ({
    children,
    ...props
}) =>
 <CSSTransition
   {...props}
   timeout={durationHeavy}
   classNames="fade"
 >
  {children}
 </CSSTransition>
