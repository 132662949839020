import React from 'react'
import styled from 'styled-components'
import {
  durationHeavy,
  easingMoveHeavy,
  white2,
} from '../styles'

const Svg = styled.svg`
  width: ${props => props.size};
  flex-shrink: 0;

  ${props => props.isLower && 'vertical-align: -2px'};

${'' /* prevents stetching vertically in flex context */}
  align-self: center;

  fill: ${props => props.shade};
${'' /* prevents icon interfering with <button> type value when using evt.target.value */}
  pointer-events: none;

  will-change: fill;
  transition: fill ${durationHeavy}ms ${easingMoveHeavy};
`

export default ({
  cat   = "home",
  size  = "20px",
  shade = white2,
  isLower = false
}) =>
  <Svg
    size={size}
    shade={shade}
    isLower={isLower}
    viewBox={(() => {
      switch (cat) {

        case 'book':
          return "0 0 1792 1792"
        
        case 'discord':
          return "25 15 196 192"

        case 'supporter':
        return "0 0 137.24 123.78"
        
        case 'logout':
          return "0 0 32 32"

        default:
          return "0 0 20 20"
      }
    })()}
    xmlns="http://www.w3.org/2000/svg"
  >
    {(() => {
        switch (cat) {

          case 'img':
            return <path d="M20,17.78V2.22A2.23,2.23,0,0,0,17.78,0H2.22A2.23,2.23,0,0,0,0,2.22V17.78A2.23,2.23,0,0,0,2.22,20H17.78A2.23,2.23,0,0,0,20,17.78ZM6.11,11.67,8.89,15l3.89-5,5,6.67H2.22Z" />

          case 'email':
            return <path d="M18,2H2A2,2,0,0,0,0,4V16a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm0,4-8,5L2,6V4l8,5,8-5Z" />

          case 'msg':
            return <path d="M18,0H2A2,2,0,0,0,0,2V20l4-4H18a2,2,0,0,0,2-2V2A2,2,0,0,0,18,0ZM16,12H4V10H16Zm0-3H4V7H16Zm0-3H4V4H16Z" />

          case 'forward':
            return <path d="M6.17,0,3.83,2.35,11.46,10,3.83,17.65,6.17,20l10-10Z" />

          case 'back':
            return <path d="M16.17,2.35,13.83,0l-10,10,10,10,2.35-2.35L8.54,10Z" />

          case 'menu':
            return <path d="M0,16.67H20V14.44H0Zm0-5.56H20V8.89H0ZM0,3.33V5.56H20V3.33Z" />

          case 'download':
            return <path d="M18.24,7.06H13.53V0H6.47V7.06H1.76L10,15.29ZM1.76,17.65V20H18.24V17.65Z" />

          case 'community':
            return <path d="M13.64,9.09a2.73,2.73,0,1,0-2.73-2.73A2.72,2.72,0,0,0,13.64,9.09Zm-7.27,0A2.73,2.73,0,1,0,3.64,6.36,2.72,2.72,0,0,0,6.36,9.09Zm0,1.82C4.25,10.91,0,12,0,14.09v2.27H12.73V14.09C12.73,12,8.48,10.91,6.36,10.91Zm7.27,0c-.26,0-.56,0-.88,0a3.84,3.84,0,0,1,1.79,3.14v2.27H20V14.09C20,12,15.75,10.91,13.64,10.91Z" />

          case 'plus':
            return <path d="M20,11.43H11.43V20H8.57V11.43H0V8.57H8.57V0h2.86V8.57H20Z" />

          case 'close':
            return <path d="M20,2,18,0,10,8,2,0,0,2l8,8L0,18l2,2,8-8,8,8,2-2-8-8Z" />

          case 'pay':
            return <path d="M18,2H2A2,2,0,0,0,0,4V16a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4A2,2,0,0,0,18,2Zm0,14H2V10H18ZM18,6H2V4H18Z" />

          case 'account':
            return <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,3A3,3,0,1,1,7,6,3,3,0,0,1,10,3Zm0,14.2A7.2,7.2,0,0,1,4,14c0-2,4-3.08,6-3.08S16,12,16,14A7.2,7.2,0,0,1,10,17.2Z" />

          case 'book':
            return <path d="M1703,478q40,57,18,129l-275,906q-19,64-76.5,107.5t-122.5,43.5h-923q-77,0-148.5-53.5t-99.5-131.5q-24-67-2-127,0-4,3-27t4-37q1-8-3-21.5t-3-19.5q2-11,8-21t16.5-23.5,16.5-23.5q23-38,45-91.5t30-91.5q3-10,.5-30t-.5-28q3-11,17-28t17-23q21-36,42-92t25-90q1-9-2.5-32t.5-28q4-13,22-30.5t22-22.5q19-26,42.5-84.5t27.5-96.5q1-8-3-25.5t-2-26.5q2-8,9-18t18-23,17-21q8-12,16.5-30.5t15-35,16-36,19.5-32,26.5-23.5,36-11.5,47.5,5.5l-1,3q38-9,51-9h761q74,0,114,56t18,130l-274,906q-36,119-71.5,153.5t-128.5,34.5h-869q-27,0-38,15-11,16-1,43,24,70,144,70h923q29,0,56-15.5t35-41.5l300-987q7-22,5-57,38,15,59,43zm-1064,2q-4,13,2,22.5t20,9.5h608q13,0,25.5-9.5t16.5-22.5l21-64q4-13-2-22.5t-20-9.5h-608q-13,0-25.5,9.5t-16.5,22.5zm-83,256q-4,13,2,22.5t20,9.5h608q13,0,25.5-9.5t16.5-22.5l21-64q4-13-2-22.5t-20-9.5h-608q-13,0-25.5,9.5t-16.5,22.5z"/>

          case 'discord':
            return (
              [<path key="0" className="st0" d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"/>,
              <path key="1" className="st0" d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"/>]
            )

          case 'supporter':
          return (
            [<path key="0" className="cls-1" d="M116.05,14.58V27L102,29.53V26.16l-5.57-7.6a41.74,41.74,0,0,0-15.7-13L77.4,3.95a20.88,20.88,0,0,0-18,0L56.17,5.51A41.74,41.74,0,0,0,40.5,18.59L35,26.16v3.37L21,27V14.58l-18.93-.5V47L18.56,58.42l8,4.48L30,78.84H43.4l4,9.47,4,.13,16.34,33.08a.65.65,0,0,0,1.16,0L85.42,88.43l4-.12,4.08-9.47H107l3.51-15.94,8-4.48L135.12,47V14.09Zm-30.6,22.9L70.77,57.36A2.3,2.3,0,0,1,67,57.3l-14.56-21a2.3,2.3,0,0,1,.26-2.93l14.91-15a2.3,2.3,0,0,1,3.35.09L85.32,34.59A2.3,2.3,0,0,1,85.45,37.48Z"/>,
             <path key="1"  className="cls-1" d="M67.21,24l-9.45,9.45a2.71,2.71,0,0,0-.28,3.5L66.92,50a2.71,2.71,0,0,0,4.39,0l9.45-13.09a2.71,2.71,0,0,0-.28-3.5L71,24A2.71,2.71,0,0,0,67.21,24Z"/>]
            )

          case 'logout':
              return  <path d="m 18,24 0,4 -14,0 0,-24 14,0 0,4 4,0 0,-8 -22,0 0,32 22,0 0,-8 z m -6,-4.003 0,-8 12,0 0,-4 8,8 -8,8 0,-4 z"/>
          
          case 'megaphone':
            return (
              <g>
                <polygon points="1 7 1 13 4 13 19 19 19 1 4 7 1 7"/>
                <circle cx="7" cy="15" r="2"/>
              </g>
            )

          default:
            return <path d="M8,18.5v-6h4v6h5v-8h3l-10-9-10,9H3v8Z" />
        }
      })()}
  </Svg>
