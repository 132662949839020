import React, { Component } from 'react'
import { Subject } from 'rxjs'
import { durationHeavy } from '../styles'
import Wrapper from './components/Wrapper'

export default class AppWrapper extends Component {

  componentWillMount = () => {
    this.routeChange$ = new Subject()
    this.props.location.state = {}

    this.routeChange$
      .subscribe(prevPathname => {

        this.timeout = setTimeout(() => window.scrollTo(0, 0), durationHeavy)

        this.props.location.state = {
          ...this.props.location.state,
          prevPathname
        }

      })
  }

  componentDidUpdate = prevProps => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.routeChange$.next(prevProps.location.pathname)
    }
  }

  componentWillUnmount()
  {
    clearTimeout(this.timeout);
  }

  render = () =>
    <Wrapper>{this.props.children}</Wrapper>

}
