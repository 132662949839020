import React, { Component } from 'react'
import {
  googleAnalytics,
  facebookPixel,
} from '../util'
import View from './View'
import data from './data'

export default class Roles extends Component {
  static defaultProps = {
    data: [...data],
  }
  state = {
    selectedName: data[0].name,
  }

  handleClick = name => {
    googleAnalytics({ evtCat: `/Home/Roles/${name}Btn` })
    facebookPixel(`/Home/Roles/${name}Btn`, 'click')

    this.setState({
      ...this.state,
      selectedName: name,
    })
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        handleClick={this.handleClick}
      />
    )
  }
}
