/* eslint no-unused-vars: "off"*/
import React from 'react'
import {
  RouteWrapper,
  Heading,
  Loader,
} from '../Common'
import Li from './Li'
import BlogPost from '../BlogPost'
import ErrMsg from './ErrMsg'
import ParagraphInput from '../EditBlogPost/ParagraphInput'

export default ({
  input,
  serverErr,
  inputChanged,
  inputCleared,
}) => {

  let parsed = undefined;

  try
  {
    parsed = JSON.parse(input);
  }
  catch (e)
  {
    serverErr = "Invalid JSON";
  }

  let post = null;

  if (!serverErr)
  {
    try
    {
      post = <BlogPost {...parsed} />;
    }
    catch (e)
    {
      console.log(e);
      serverErr = e;
    }
  }

  const isServerErr = !!serverErr

  return (
    <RouteWrapper>
      <Heading txt="Preview Blog" />
      <ParagraphInput
                name={"Input"}
                handleInput={inputChanged}
                handleRemoveClick={inputCleared}
                val={input}
              />
      {post}
      {isServerErr && <ErrMsg>{serverErr}</ErrMsg>}
    </RouteWrapper>
  )
}
