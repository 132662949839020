import React from 'react'
import Header from './Header'
import Links from './Links'
import MenuBtn from './MenuBtn'
import FooterImg from './FooterImg'

export default ({
  currentPathname,
  data,
  isMobile,
  isDown,
  handleMenuBtnClick,
  handleLinkClick,
}) =>
  <Header isDown={isDown}>
    {(!isMobile || (isMobile && isDown)) &&
      <Links
        currentPathname={currentPathname}
        data={data}
        isMobile={isMobile}
        handleLinkClick={handleLinkClick}
      />
    }
    {!isDown && isMobile && <MenuBtn handleClick={handleMenuBtnClick} />}
    <FooterImg />
  </Header>
