/* eslint-disable */
// @flow
import React, { Component } from 'react';

var XPayStationWidget = require('./xsolla');

/*
* **access_token** — Access token
* **host** - Host for performing requests. The default value is **secure.xsolla.com**
* **sandbox** — Set **true** to test the payment process, sandbox-secure.xsolla.com will be used instead of **host**
* **lightbox** — Options for modal dialog that contains frame of PayStation
    * **width** — Width of lightbox frame. If null, depends on paystation width. Default is null
    * **height** — Height of lightbox frame. If null, depends on paystation height. Default is '100%'
    * **zIndex** — Property controls the vertical stacking order, default is 1000
    * **overlayOpacity** — Opacity of the overlay (from 0 to 1), default is '.6'
    * **overlayBackground** — Background of the overlay, default is '#000000'
    * **modal** - Lightbox frame cannot be closed, default false
    * **closeByClick** — Toggle if clicking the overlay should close lightbox, default true
    * **closeByKeyboard** — Toggle if pressing of ESC key should close lightbox, default true
    * **contentBackground** — Background of the frame, default is '#ffffff'
    * **contentMargin** — margin around frame, default '10px',
    * **spinner** — Type of animated loading spinner, can be 'xsolla', 'round', 'none' or 'custom', default is the first one
    * **spinnerColor** — Color of the spinner, not set by default
    * **spinnerUrl** — URL of custom spinner, default is null
    * **spinnerRotationPeriod** — Rotation period of custom spinner, default 0
* **childWindow** — Options for child window that contains PayStation. Suitable for mobile version
    * **target** — The target option specifies where to open the Paystation window, can be '_blank', '_self', '_parent', default is '_blank'
    */


/*
INIT: 'init',
OPEN: 'open',
OPEN_WINDOW: 'open-window', MOBILE ONLY VERSION OF OPEN
OPEN_LIGHTBOX: 'open-lightbox', DESKTOP ONLY VERSION OF OPEN
LOAD: 'load',
CLOSE: 'close',
CLOSE_WINDOW: 'close-window', MOBILE ONLY VERSION OF CLOSE
CLOSE_LIGHTBOX: 'close-lightbox', DESKTOP ONLY VERSION OF CLOSE
STATUS: 'status',
STATUS_INVOICE: 'status-invoice',
STATUS_DELIVERING: 'status-delivering',
STATUS_TROUBLED: 'status-troubled',
STATUS_DONE: 'status-done'*/

var lightbox = 
{
    width: null,
    height: '100%',
    zIndex: 1000,
    overlayOpacity: .6,
    overlayBackground: '#000000',
    modal: false,
    closeByClick: true,
    closeByKeyboard: true,
    contentBackground: '#ffffff',
    contentMargin: '10px',
    spinner: 'xsolla', //'xsolla', 'round', 'none', or 'custom'
    //spinnerColor: '#ffffff', //To force a color
    spinnerUrl: 'https://customSpinner.png', //custom spinner url
    spinnerRotationPeriod: 0, //custom spinner rotation period
}

export default class Checkout extends Component
{
    constructor(props)
    {
        super(props);

        props.setShowMethod(this.show.bind(this));

        this.state =
        {
            listeners :
            {
                ["init"] : this.onInit.bind(this),
                ["open"] : this.onOpen.bind(this),
                ["load"] : this.onLoad.bind(this),
                ["close"] : this.onClose.bind(this),
                ["status"] : this.onStatus.bind(this),
                ["status-invoice"] : this.onInvoice.bind(this),
                ["status-delivering"] : this.onDelivering.bind(this),
                ["status-troubled"] : this.onTroubled.bind(this),
                ["status-done"] : this.onDone.bind(this),
            }
        }
    }

    show(token)
    {
        console.log(token);

        var config =
        {
            access_token: token,
            sandbox: this.state.sandbox,
            lightbox
        };

        this.setState({config}); 
    }

    onInit(args)
    {
        console.log("xsolla init");
        console.log(args);
        this.props.onInit && this.props.onInit(args);
    }
    
    onOpen(args)
    {
        console.log("xsolla open");
        console.log(args);
        this.props.onOpen && this.props.onOpen(args);
    }

    onLoad(args)
    {
        console.log("xsolla load");
        console.log(args);
        this.props.onInit && this.props.onLoad(args);
    }

    onClose(args)
    {
        console.log("xsolla close");
        console.log(args);

        this.setState({config:null});

        this.props.onClose && this.props.onClose(args);
    }

    onStatus(args)
    {
        console.log("xsolla status");
        console.log(args);
        this.props.onStatus && this.props.onStatus(args);
    }
    
    onInvoice(args)
    {
        console.log("xsolla invoice");
        console.log(args);
        this.props.onInvoice && this.props.onInvoice(args);
    }

    onDelivering(args)
    {
        console.log("xsolla delivering");
        console.log(args);
        this.props.onDelivering && this.props.onDelivering(args);
    }

    onTroubled(args)
    {
        console.log("xsolla troubled");
        console.log(args);
        this.props.onTroubled && this.props.onTroubled(args);
    }

    onDone(args)
    {
        console.log("xsolla done");
        console.log(args);
        this.props.onDone && this.props.onDone(args);
    }

    render()
    {
        if (!this.state.config || !this.state.config.access_token)
        {
            return null;
        }

        console.log("Showing xsolla overlay");

        return <Xsolla {...this.state}/>;
    }
}

class Xsolla extends Component
{
    constructor(props)
    {
        super(props);

        XPayStationWidget.off();

        for (var key in props.listeners) 
        {
            if (props.listeners.hasOwnProperty(key))
            {
                XPayStationWidget.on(key, props.listeners[key]);
            }
        }
        
        XPayStationWidget.on("init", args => { console.log("xsolla init"); console.log(args); });
        XPayStationWidget.on("open", args => { console.log("xsolla open"); console.log(args); });
        XPayStationWidget.on("close", args => { console.log("xsolla close"); console.log(args); });
        XPayStationWidget.on("status", args => { console.log("xsolla status"); console.log(args); });
        XPayStationWidget.on("status-invoice", args =>{  console.log("xsolla status-invoice"); console.log(args); });
        XPayStationWidget.on("status-delivering", args =>{  console.log("xsolla status-delivering"); console.log(args); });
        XPayStationWidget.on("status-delivering", args => { console.log("xsolla status-delivering"); console.log(args); });
        XPayStationWidget.on("status-troubled", args => { console.log("xsolla status-troubled"); console.log(args); });
        XPayStationWidget.on("init", args => { console.log("xsolla status-done"); console.log(args); });

        XPayStationWidget.init(this.props.config);
    }


    componentDidMount() 
    {
        XPayStationWidget.open();
    }
      
    componentWillUnmount() 
    {
        XPayStationWidget.off();
    }

    render()
    {
        return null;
    }
}