import React from 'react'
import styled, { css } from 'styled-components'
import {
  googleAnalytics,
} from '../util'
import {
  durationHeavy,
  easingMoveHeavy,
} from '../styles'
import {
  SwipeWrapper,
  DownloadLink,
} from '../Common'
import ScrollImgBtn from './ScrollImgBtn'

/*
  the issue with this element is that it has a set height (unlike other wrappers)
  to get around cascading height: 100%, max-height: ...vh is being used
*/

const TOOLBAR_Y = 80
const GAP = 16
const DOWNLOAD_BTN_Y = GAP + 48 + 48

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background: hsla(0, 0%, 0%, 0.9);
`

const CloseBtn = styled.button`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
`

const Content = styled.div`
  width: 100%;
  height: 100%;

  padding: ${TOOLBAR_Y}px 8px 8px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`
  max-height: calc(100vh - ${TOOLBAR_Y + DOWNLOAD_BTN_Y}px);

  ${'' /* 1st img sets the dimensions, the rest are layered on top */}
  ${props => props.index > 0 && css`
    position: absolute;
    top: 0;
    left: ${props.index * 100}%;
  `}
  transform: translate3d(${props => props.currImgNum * -100}%, 0px, 0px);

  will-change: transform;
  transition: transform ${durationHeavy}ms ${easingMoveHeavy};
`

const Relative = styled.div`
  position: relative;
  overflow-x: hidden;
  box-shadow: 0 4px 5px 0     rgba(0, 0, 0, 0.14),      0 1px 10px 0     rgba(0, 0, 0, 0.12),   0 2px 4px -1px  rgba(0, 0, 0, 0.2);
`

const Spacer = styled.div`
  height: ${props => props.y};
`

export default ({
  imgs = [],
  largeImgs = [],
  currImgNum = 0,
  handlePrevImgClick = () => console.log('handlePrevImgClick'),
  handleNextImgClick = () => console.log('handleNextImgClick'),
  handleLightboxBackgroundClick = () => console.log('handleLightboxBackgroundClick')
}) => {

  return (
    <SwipeWrapper
      // handleSwipeLeft={handleNextImgClick}
      // handleSwipeRight={handlePrevImgClick}
    >
      <Wrapper>
        <CloseBtn
          type="button"
          onClick={handleLightboxBackgroundClick}
        />
        <Content>
          <Relative>
            <ul>
              {imgs.map((img, i) =>
                <li key={i}>
                  <Img
                    index={i}
                    currImgNum={currImgNum}
                    src={img.med}
                    alt={img.alt}
                  />
                </li>
              )}
            </ul>
            <ScrollImgBtn
              handleClick={handlePrevImgClick}
              isBack
            />

            <ScrollImgBtn
              handleClick={handleNextImgClick}
            />
          </Relative>
          <Spacer y={`${GAP}px`}/>
          <DownloadLink
            onClick={googleAnalytics.bind(this, { evtCat: `/Lightbox/downloadImg${currImgNum}Btn` })}
            href={imgs[currImgNum].large}
            fileName={imgs[currImgNum].fileName}
          >
            Download
          </DownloadLink>
        </Content>
      </Wrapper>
    </SwipeWrapper>
  )
}
