import React from 'react'
import styled from 'styled-components'
import {
  cap1stLetter,
  timestampToDateStr,
} from '../util'
import { yellow1 } from '../styles'

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  div {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 34px;
    line-height: 1.3;
    letter-spacing: 0;
    text-align: left;
    color: ${yellow1};
  }
`

export default ({
  title,
  author,
  enteredAt,
  url
}) =>
  <Header>
    <img
      src={`https://s3-ap-southeast-2.amazonaws.com/township-blog/author-avatars/${author}.png`}
      alt=""
    />
    <div>
      <h2 itemProp="name">{title}</h2>
      <a href={url}>{timestampToDateStr({ timestamp: enteredAt })}</a>
      <p>from {cap1stLetter(author)}</p>
    </div>
  </Header>
