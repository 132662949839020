import React from 'react'
import styled from 'styled-components'
import {
  skull,
} from '../imgs'

const Img = styled.img`
  width: 300px;
`

export default () =>
  <Img
    src={skull}
    alt=""
  />
