import React from 'react'
import Wrapper from './Wrapper'
import AboveTheFold from '../AboveTheFold'
// import GameDescription from '../GameDescription'
import JoinTheTownship from '../JoinTheTownship'
import Roles from '../Roles'
import LatestBlogPost from '../LatestBlogPost'
import Lightbox from './Lightbox'
import Skull from './Skull'
import Footer from '../Footer'

export default ({
  imgs,
  currImgNum,
  isShowLightbox,
  handleCTAClick,
  handleLightboxBackgroundClick,
  handleNextImgClick,
  handlePrevImgClick,
}) =>
  <Wrapper cat="routeSectionHome">
    <AboveTheFold handleCTAClick={handleCTAClick} />
    {/* <GameDescription/> */}
    <JoinTheTownship>
      <div id="scrollTarget"></div>
    </JoinTheTownship>
    <Roles />
    <LatestBlogPost />
    <Skull />
    {isShowLightbox &&
      <Lightbox
        imgs={imgs}
        currImgNum={currImgNum}
        handlePrevImgClick={handlePrevImgClick}
        handleNextImgClick={handleNextImgClick}
        handleLightboxBackgroundClick={handleLightboxBackgroundClick}
      />
    }
    {!isShowLightbox && <Footer/>}
  </Wrapper>
