/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react'
import sha512 from 'crypto-js/sha512'
// import CryptoJS from 'crypto-js'
// var sha512 = require('js-sha512')
//import sha512 from 'js-sha512'
import {
  isPassword,
} from '../util'
import View from './View'

import queryString from 'query-string';
import { SetPassword } from '../api/index';

export default class ResetPassword extends Component {
  static defaultProps = {}
  state = {
    password: {
      isFocus: false,
      val: '',
    },
    confirmPassword: {
      isFocus: false,
      val: '',
    },
    isAwaitingResponse: false,
    serverErr: null,
    successMsg: null,
  }

  handleClear = () => {
    this.setState((prevState, props) => ({
      ...prevState,
      serverErr: null,
      successMsg: null
    }))
  }

  handleFocus = evt => {
    const { name } = evt.target

    this.setState((prevState, props) => ({
      ...prevState,
      [name]: {
        ...prevState[name], 
        isFocus: true,
      }
    }))
  }

  handleBlur = evt => {
    const { name } = evt.target

    this.setState((prevState, props) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        isFocus: false,
      }
    }))
  }

  handleInput = evt => {
    const { name, value } = evt.target

    this.setState((prevState, props) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        val: value,
        err: null,
      }
    }))
  }

  handleSubmit = evt => 
  {
    evt.preventDefault()

    if (!this.isInputValid()) return

    var { userId, token } = queryString.parse(this.props.location.search);

    const {
      password,
      confirmPassword,
    } = this.state

    var passwordHash = sha512(password.val).toString()

    this.setState({isAwaitingResponse:true});

    SetPassword(userId, passwordHash, token, (isOk, body) =>
    {
      if(isOk)
        this.setSuccessMsg()
      else
        this.setServerErr(body.message);   
    },
    error =>
    {
      this.setServerErr(error.message);
    });
  }

  isInputValid = () => {
    const {
      password,
      confirmPassword,
    } = this.state

    if (!isPassword(password.val)) {
      this.setInputErr('password', "Need at least 8 characters to be secure!")
      return false

    } else if (password.val !== confirmPassword.val) {
      this.setInputErr('confirmPassword', 'Passwords need to match!')
      return false

    } else return true
  }

  setSuccessMsg = () => {
    this.setState((prevState, props) => ({
      ...prevState,
      isAwaitingResponse: false,
      successMsg: `Password Reset!`
    }))
  }

  setInputErr = (inputName, msg) =>
    this.setState((prevState, props) => ({
      ...prevState,
      [inputName]: {
        ...prevState[inputName],
        err: msg,
      }
    }))

  setServerErr = msg =>
    this.setState((prevState, props) => ({
      ...this.state,
      isAwaitingResponse: false,
      serverErr: msg
    }))

  render() {

    return (
      <View
        {...this.props}
        {...this.state}
        handleFocus={this.handleFocus}
        handleBlur={this.handleBlur}
        handleInput={this.handleInput}
        handleSubmit={this.handleSubmit}
        handleClear={this.handleClear}
      />
    )
  }
}
