import React from 'react'
import styled, { keyframes } from 'styled-components'
import {
  white2,
  white3
} from '../styles'

const Svg = styled.svg`
  width:  48px;
  height: 48px;
`

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
`

const Circle = styled.circle`
  stroke: ${white3};
  fill: none;
  stroke-width: 2px;
`

const Arc = styled.circle`
  stroke: ${white2};
  fill: none;
  stroke-width: 2px;
  stroke-dasharray: 60;
  stroke-dashoffset: 45;
  transform-origin: 50% 50%;
  animation: ${rotate} 2s infinite linear;
`

export default () =>
  <Svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle
      cx="12"
      cy="12"
      r="9.5"
    />
    <Arc
      cx="12"
      cy="12"
      r="9.5"
    />
  </Svg>
