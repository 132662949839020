/*
  <Footer /> needs to be included on every route to inlcude it in route transitions
*/

import React from 'react'
import styled from 'styled-components'
import {
  routeTransition,
  TOOLBAR_UP_HEIGHT,
} from '../styles'
import {
  rock1,
  rock2,
  rock3,
  rock4,
} from '../imgs'
import { FOOTER_HEIGHT  } from '../Footer/Wrapper'
import Footer from '../Footer'

const Section = styled.section`
  width: 100%;
  min-height: 100vh;

  position: absolute;
  top: 0;
  left: 0;

  padding: ${TOOLBAR_UP_HEIGHT + 48}px 8px ${FOOTER_HEIGHT + 48}px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

${'' /* imgs are distanced from the center to ensure they don't overlap txt */}
${'' /* x-pos y-pos/width */}
  background:
    url(${rock1}) calc(50% - 620px) 140px/200px no-repeat,
    url(${rock2}) calc(50% + 580px) 240px/300px no-repeat,
    url(${rock3}) calc(50% + 680px) 1340px/300px no-repeat,
    url(${rock4}) calc(50% - 680px) 1640px/300px no-repeat,
    url(${rock2}) calc(50% + 680px) 1940px/270px no-repeat;

  ${routeTransition()}
`

export default ({
  children = 'RouteWrapperChild'
}) =>
  <Section>
    {children}
    <Footer />
  </Section>
