import React from 'react'
import ReactDOM from 'react-dom'
import { injectGlobal } from 'styled-components'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import { global } from './styles'
import { unregister } from './registerServiceWorker'
import smoothscrollPolyfill from 'smoothscroll-polyfill'

// registerServiceWorker()
unregister()
smoothscrollPolyfill.polyfill()
ReactDOM.render(<App />, document.getElementById('root'))

// eslint-disable-next-line
injectGlobal`${global}`
