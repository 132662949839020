import React from 'react'
import styled, { css } from 'styled-components'
import {
  cap1stLetter,
  spaceB4Caps,
} from '../util'
import {
  yellow2,
  yellow1,
  red,
  SECOND_TXT_COLOR,
  durationLight,
  easingMoveLight
} from '../styles'

const Wrapper = styled.div`
  align-self: center;

  width: 100%;
  max-width: ${props => props.size};
  ${'' /* margin-bottom: 8px; */}

  ${'' /* padding: 8px; */}
  display: flex;
  flex-direction: column;

  ${props => props.isDisable && css`
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  `}

  will-change: opacity, filter;
  transition: all ${durationLight}ms ${easingMoveLight};
`

const Label = styled.label`
  text-transform: uppercase;
  color: ${SECOND_TXT_COLOR};
  overflow: hidden;
  pointer-events: none;
  will-change: tranform, color;
  transition: all ${durationLight}ms ${easingMoveLight};

  ${props => props.isLowered && css`
    color: ${yellow1};
    transform: translate3d(0, 24px, 0);
  `}
`

const Span = styled.span`
  ${'' /* required to scale */}
  font-size: 18px;
  display: inline-block;
  transform-origin: 0 0;
  ${props => props.isLowered && css`
    transform: scale(1.5);
    color: ${yellow1};
  `}
  will-change: tranform, color;
  transition: all ${durationLight}ms ${easingMoveLight};
`

const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${yellow2};
  background: none;
`

const Line = styled.div`
  width: ${props => props.isVisible ? '100%' : 0};
  height: 1px;

  position: relative;
  top: -1px;

  background: ${yellow1};
  will-change: width;
  transition: width 0.3s linear;
`

const ErrMsg = styled.p`
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  color: ${red};

  opacity: ${props => props.isShow ? 1 : 0};
  will-change: opacity;
  transition: opacity ${durationLight}ms ${easingMoveLight};
`

export default ({
  name         = 'email',
  label        = undefined,
  isFocus      = false,
  isDisable    = false,
  handleFocus  = () => console.log('handleFocus'),
  handleBlur   = () => console.log('handleBlur'),
  handleInput  = evt => console.log('handleInput'),
  value          = '',
  error          = null,
  size         = 'calc(9 * 48px)',
  isPassword   = false,
}) => {
  label = label || name;

  const formatedLabel = cap1stLetter(spaceB4Caps(label))
  const isValue = value.length > 0
  const isErr = !!error

  return (
    <Wrapper
      size={size}
      isDisable={isDisable}
    >
      <Label isLowered={!isFocus && !isValue}>
        <Span isLowered={!isFocus && !isValue}>{formatedLabel}:</Span>
      </Label>
      <Input
        name={name}
        value={value}
        type={isPassword ? 'password' : 'text'}
        disabled={isDisable}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInput}
      />
      <Line isVisible={isFocus}/>
      <ErrMsg isShow={isErr}>{isErr && error}</ErrMsg>
    </Wrapper>
  )
}
