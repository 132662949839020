import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`

export default ({
  children = <div>wrapper child</div>
}) =>
  <Wrapper>{children}</Wrapper>
