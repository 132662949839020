/* eslint no-unused-vars: "off"*/
import React from 'react'
import {
  RouteWrapper,
  Heading,
  Form,
  TxtInput,
  Submit,
  Icon,
} from '../Common'

export default ({
  isAwaitingResponse = true,
  serverErr = null,
  isSuccess = false
}) =>
  <RouteWrapper>
    <Heading txt={isSuccess ? "Awesome!" : "Email Verification"}/>

    {isAwaitingResponse ? 
      <p>Verifying email...</p> : 
      (isSuccess ? <p>Email verified.<br/>You can now log in!</p> :
      <p>{serverErr}</p>)}

  </RouteWrapper>