import React from 'react'
import styled, { css } from 'styled-components'
import { ShareButtons } from 'react-share'
import {
  white3,
  easingEnterLight,
  durationLight,
} from '../styles'
import {
  chain,
  facebook,
  twitter,
  discord,
} from '../imgs'
import {
  ClickableImg,
} from '../Common'
const {
  FacebookShareButton,
  TwitterShareButton
} = ShareButtons

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  & *:focus{
    outline: none;
  }

  &>* {
    margin-right: 24px;
  }
  &>*:first-child,
  &>*:last-child {
    margin-right: 0;
  }
`

const Pointer = styled.div`
  cursor: pointer;
`

const Input = styled.input`
  width: 0;
  padding: 0;

  opacity: 0;
  background: none;
  outline: 1px solid ${white3};

  &:hover{
    outline: 1px solid ${white3};
  }

  ${props => props.isShow && css`
    opacity: 1;
    flex-grow: 1;
    margin-left: 16px;
    padding: 4px;
  `}

  transition: all ${durationLight}ms ${easingEnterLight};
`

const UrlBtn = styled.button`
  &:disabled{
    filter: grayscale();
  }
`

export default ({
  title = 'title',
  url = 'https://www.townshiptale.com/blog',
  isShowUrl = false,
  handleUrlClick = () => console.log('handleUrlClick'),
  handleShareBtnClick = () => console.log('handleShareBtnClick'),
}) =>
  <Wrapper>

    <UrlBtn
      disabled={isShowUrl}
      type="button"
      onClick={handleUrlClick.bind(this, title)}
    >
      <ClickableImg
        img={chain}
        size="40px"
        isClickable
      />
    </UrlBtn>

    <Input
      defaultValue={url}
      isShow={isShowUrl}
      readOnly
    />

    <Pointer onClick={handleShareBtnClick.bind(this, title, 'twitter')}>
      <TwitterShareButton
        title={title}
        url={url}
        hashtags={['a-township-tale']}
      >
        <ClickableImg
          img={twitter}
          size="40px"
          isClickable
        />
      </TwitterShareButton>
    </Pointer>

    <Pointer onClick={handleShareBtnClick.bind(this, title, 'facebook')}>
      <FacebookShareButton
        url={url}
        quote={title}
      >
        <ClickableImg
          img={facebook}
          size="40px"
          isClickable
        />
      </FacebookShareButton>
    </Pointer>

    <a
      onClick={handleShareBtnClick.bind(this, title, 'discord')}
      href="https://discord.gg/ppEAy7D"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ClickableImg
        img={discord}
        size="40px"
        isClickable
      />
    </a>

  </Wrapper>
