import React, { Component } from 'react'
import SERVER_URL from '../SERVER_URL'
import { genTodaysTimestamp } from '../util'
import View from './View'

export default class LatestBlogPost extends Component {
  state = {
    isAwaitingPost: true,
    blogPost: null,
    serverErr: null
  }
  fetchPost = this.fetchPost.bind(this)

  componentDidMount() {
    this.fetchPost({ timestamp: genTodaysTimestamp() })
  }

  fetchPost({
    timestamp = 1504718409,
  } = {}) {
    // this is using header & req objects (different than other fetch requests that just use strings)
    const url = `${SERVER_URL}/read-blog-post/${timestamp}`

    const headers = new Headers()
    headers.append("Access-Control-Allow-Origin", "*")

    const init = {
      method: 'GET',
      headers,
    }

    const req = new Request(url, init)

    fetch(req)
      .then(res => {
        if (res.status !== 200) throw Error(res)
        return res.json()
      })
      .then(json => {

        // uses the first img & Nth paragraph as content instead of the whole blog post
        const  nPara = 5;
        const content = json.content[0].type === 'img'
          ? json.content.slice(0, nPara)
          : json.content.slice(0, nPara - 1)

        this.setState({
          ...this.state,
          isAwaitingPost: false,
          blogPost: {
            ...json,
            content,
          }
        })
      })
      .catch(err =>
        this.setState({
          ...this.state,
          serverErr: 'There was a problem connecting the the server. Please try again later.'
        })
      )
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
      />
    )
  }
}
