
const LiveURL = `https://967phuchye.execute-api.ap-southeast-2.amazonaws.com/`

const PossibleEndPoint =
{
    Development : LiveURL + 'dev/api/',
    Production : LiveURL + 'prod/api/',
    Local : 'http://localhost:13490/api/',
}

const Resource =
{
    Launcher : "launcher/",
    Sessions : "sessions/",
    Servers  : "servers/",
    Users : "users/",
    Services : "services/",
}

function Fetch(url, method, body, onResponse, onError)
{
    const headers = new Headers(
    {
      'Content-Type': 'application/json',
      'x-api-key': '2l6aQGoNes8EHb94qMhqQ5m2iaiOM9666oDTPORf'
    });

    body = JSON.stringify(body);
     
    let isOk = false;

    fetch(url,
    {
      method,
      headers,
      body
    })
    .then(response => 
    {      
        isOk = response.ok;
        return response.json();
    })
    .then(body =>
    {
        onResponse(isOk, body);
    })
    .catch(err =>
    {
        onError(err);
    });
}

//Implementation

const EndPoint = PossibleEndPoint.Production;


export function VerifyUser(userId, token, onResponse, onError)
{
    let body = { "verification_token" : token };
    let url = EndPoint + Resource.Users + userId + '/verification';

    Fetch(url, "POST", body, onResponse, onError);
}

export function SetPassword(userId, passwordHash, token, onResponse, onError)
{
    let body = 
    { 
        "new_password_hash": passwordHash,
        "reset_token": token,
    };

    let url = EndPoint + Resource.Users + userId + '/password';

    Fetch(url, "POST", body, onResponse, onError);
}