/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react'
import { Observable } from 'rxjs'
import {
  swipe$,
  googleAnalytics,
  facebookPixel,
} from '../util'

import { Sessions } from '../webApiClient';

import View from './View'

export default class Toolbar extends Component {
  static defaultProps = {
    currentPathname: '/',
    data: [
      {
        pathname: '/',
        iconCat: 'home',
        txt: 'Home',
      },
      {
        pathname: '/blog',
        iconCat: 'msg',
        txt: 'Blog',
      },
      {
        pathname: '/wiki',
        iconCat: 'book',
        txt: 'Wiki',
      },
      {
        pathname: '/discord',
        iconCat: 'discord',
        txt: 'Discord',
      },
      {
        pathname: '/supporter',
        iconCat: 'supporter',
        txt: 'Supporter',
      },
    ],
  }
  state = {
    isMobile: false,
    isDown: false,
  }

  componentDidMount() {
    this.subscribeToMobile$()
    this.subscribeToScroll$()
    this.subscribeToSwipe$()
  }

  componentWillUnmount() {
    this.mobile$Sub.unsubscribe()
    this.scroll$Sub.unsubscribe()
    this.swipe$Sub && this.swipe$Sub.unsubscribe()
  }

  // TODO: filter this if it passes the threshold
  subscribeToMobile$ = () =>
    this.mobile$Sub = Observable
      .fromEvent(window, 'resize')
      .map(evt => window.innerWidth)
      .startWith(window.innerWidth)
      .subscribe(width =>
        this.setState({
          ...this.state,
          isMobile: width < 640,
          isDown: false,
        })
      )

  subscribeToScroll$ = () =>
    this.scroll$Sub = Observable
      .fromEvent(window, 'scroll')
      .filter(() => this.state.isDown)
      .subscribe(() => this.setState({
        ...this.state,
        isDown: false,
      }))

  subscribeToSwipe$ = () =>
    swipe$({ domElem: document.querySelector('#toolbar') })
      .map(coords => coords.startY < coords.endY ? 'down' : 'up')
      .subscribe(direction =>
        this.setState({
          ...this.state,
          isDown: direction === 'down'
        })
      )

  translateDown = () =>
    this.setState({
      ...this.state,
      isDown: true,
    })

  translateUp = () =>
    this.setState({
      ...this.state,
      isDown: false,
    })

  handleMenuBtnClick = () =>
    this.translateDown()

  handleLinkClick = btnTxt => {
    googleAnalytics({ evtCat: `/Toolbar/${btnTxt}Btn` })
    facebookPixel(`/Toolbar/${btnTxt}Btn`, 'click')
    this.translateUp()
  }

  render() {

    var data = Sessions.getLoggedIn() ?
    [...this.props.data,
    {
      pathname: '/account/logout',
      iconCat: 'logout',
      txt: 'Log Out',
    }]
    :
    this.props.data;

    return (
      <View
        {...this.props}
        {...this.state}
        data={data}
        handleMenuBtnClick={this.handleMenuBtnClick}
        handleLinkClick={this.handleLinkClick}
      />
    )
  }
}
