import React, { Component } from 'react'
import { swipe$ } from '../util'

export default class SwipeWrapper extends Component {

  static defaultProps = {
    handleSwipeLeft: () => console.log('handleSwipeLeft'),
    handleSwipeRight: () => console.log('handleSwipeRight'),
    children: <div>child</div>
  }

  componentDidMount = () => {
    const { wrapper } = this
    const {
      handleSwipeRight,
      handleSwipeLeft
    } = this.props

    const horzSwipe$ = swipe$({ domElem: wrapper })
      .map(coords => ({
        startX: coords.startX,
        endX: coords.endX
      }))

    const direction$ = horzSwipe$
      .map(coords => coords.startX > coords.endX
        ? 'left'
        : 'right'
      )

    this.direction$Subscr = direction$
      .subscribe(direction => {
        direction === 'left'
          ? handleSwipeLeft()
          : handleSwipeRight()
      })
  }

  componentWillUnmount = () =>
    this.direction$Subscr.unsubscribe()

  render = () => {
    const { children } = this.props

    return(
      <div ref={node => this.wrapper = node}>
        {children}
      </div>
    )
  }
}
