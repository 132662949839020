/* eslint no-unused-vars:"off" */

import React from 'react'
import {
  Heading,
  Form,
  TxtInput,
  Submit,
  Icon,
  RouteLink,
} from '../Common'
import Wrapper from './Wrapper'
import P from './P'

export default ({
  isEmailFocus,
  email,
  emailErr,

  isAwaitingResponse,
  isSuccess,
  serverErr,

  handleFocus,
  handleBlur,
  handleInput,
  handleSubmit,
  children
}) =>
  <Wrapper>
    {/* CTA target */}
    {children}
    <Heading txt="Join the Township" />
    <P>To play the free pre-alpha, just join the community discord, and follow the instructions there!</P>
    <br/>
    <RouteLink
          to='/play'
        >
          join the discord
    </RouteLink>
    <br/>
    <br/>
    {/* <Heading txt="Join the Mailing List" />
    <P>To get a sneak peek at the newest updates, art and videos.</P>
    <P>Sign up here!</P>
    <Form>
      <TxtInput
        name="email"
        value={email}
        error={emailErr}
        isFocus={isEmailFocus}
        isDisable={isAwaitingResponse}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        handleInput={handleInput}
      />
      <Submit
        isAwaitingResponse={isAwaitingResponse}
        handleSubmit={handleSubmit}
        error={serverErr}
        successMsg={isSuccess ? 'Sign up successful. Welcome to the township!' : null}
      >
        <span>
          <Icon
            cat="email"
            isLower
          />
          &nbsp;
          Sign Up
        </span>
      </Submit>
    </Form> */}
   
  </Wrapper>
