import React from 'react'
import styled from 'styled-components'

const Li = styled.li`
  margin-bottom: 96px;
`

export default ({
  children = <div>LiChild</div>
}) =>
  <Li>{children}</Li>
