import { css } from 'styled-components'
import {
  APP_BACKGROUND_COLOR,
  PRIMARY_TXT_COLOR,
} from './colors'
// won't allow import from ../Common (unkonwn why)
import { fadeTransitionStyles } from '../Common/Fade'
import stripe from './stripe'

export const global = css`
  blockquote, pre,
  abbr, acronym, address, big, cite, code,
  del, dfn, em, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt,
  center, caption,
  canvas, details, embed,
  figure, figcaption,  hgroup,
  time, mark, audio, video,
  html, body, #root, footer, header, nav, menu, aside, section, article, div,
  iframe, img,
  h1, h2, h3, h4, h5, h6, p, span, b, u, i,
  dl, dt, dd, ol, ul, li,
  table, tbody, tfoot, thead, tr, th, td,
  form, input, textarea, fieldset, label, legend,
  button, a {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    vertical-align: baseline;

    @import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,700');
    font-family: 'Josefin Sans', sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0;
    text-align: left;

    color: currentColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body,
  #root {
    width: 100%;
    min-height: 100vh;

    color: ${PRIMARY_TXT_COLOR};
    background: ${APP_BACKGROUND_COLOR};
  }

  p{
    /* You should have around 60 characters per line if you want a good reading experience */
    /*max-width: calc(13 * 48px); */

    /* aligns with images */
    max-width: 640px;
  }

  img,
  iframe {
    /* so imgs will resize based on wrapper constraints */
    max-width: 100%;
    /* removes space below set by display: inline */
    display: block;
    /* prevent image from stretching due to 'align-self: default' value is stretch (in flexbox context) */
    align-self: center;
  }

  button {
    /* removes space below set by display: inline */
    display: block;

    cursor: pointer;
    background: none;
    outline: none;
  }

  button:disabled {
    pointer-events: none;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

${'' /* removes the yellow background & black txt color in Chrome when user clicks auto-suggest */}
  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px ${APP_BACKGROUND_COLOR} inset !important;
      -webkit-text-fill-color: ${PRIMARY_TXT_COLOR} !important;
  }

  ${fadeTransitionStyles}
  ${stripe}
`
