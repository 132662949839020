import styled from 'styled-components'
import background from './imgs/background.svg'

export default styled.section`
  width: 100%;
  margin-bottom: 96px;
  padding: 0 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${'' /* to render a repeated .svg without gaps, you need to set preserveAspectRatio="none" & background-size */}
  background-image: url(${background});
  background-position: 0px 0px;
${'' /* set the sizes to the .svg viewbox & use multipliers to size */}
  background-size: calc(1440px * 0.5) calc(915px * 0.5);
  background-repeat: repeat-x;
`
