/* eslint no-unused-vars: "off" */
import React, { Component } from 'react';
import styled, {css} from 'styled-components'

export const Page = styled.div`

    position: fixed;
    
    width: 100%;
    max-height: 100%;
    height: auto;

    background-image: url('https://s3-us-west-1.amazonaws.com/a-township-tale/shop/assets/SupportBackground.png');
    background-size: cover;
    background-repeat: repeat-y;

    overflow: auto;
    
    color: #F9F3F3;
    font-size: 34px;
    font-weight: bold;
    font-family: 'JosefinSans';
`

export const Wrapper = styled.div`
    
    margin: auto;
    margin-top: -90px;

    transform: scale(0.75);

    width: 100%;
    height: auto;

    display: flex;
    align-items: center;

    flex-direction: column;

    overflow: visible;
`

export const SupportBoard = styled.div`
    position: relative;

    background-image: url('https://s3-us-west-1.amazonaws.com/a-township-tale/shop/assets/SupportBoard.png');
    background-repeat: no-repeat;

    color: black;
 
    font-size: 24px;

    width:  1114px;
    height: 814px;

    flex-shrink: 0;
    flex-grow: 0;

    border-radius: 5px;

    margin-bottom: -100px;
`;

const Perks = styled.ul`
    width: 900px;
    margin: auto;

    margin-top: 300px;

    padding-right: 40px;

    display: flex;
    flex-flow: wrap column;
    align-items: center;

    text-align: center;

    >li
    {
        list-style-type: none;

        font-size: 24px;
        line-height: 46px;

        width: 50%;
        text-align: center;

        >img
        {
            height: 34px;
            width: auto;

            display: inline-block;

            margin-bottom: -10px;
        }
    }
`;

export var PerkList = ({items}) =>
{
    if (!items)
    {
        return null;
    }

    var index = 0;

    return (
    <Perks>
        {items.map(item =>
            {
                var parts = item.split(/\<(.+)\>/gi);

                for (var i = 1; i < parts.length; i += 2) 
                {
                    parts[i] = <img src={parts[i]}/>;
                }

                return <li key={index++}>{parts}</li>
            })}
    </Perks>);
}

export const Disclaimer = styled.p`

    position: fixed;

    bottom: 0px;
    height: 60px;

    font-size: 16px;
    text-align: center;

    margin: 0px;

    width: 100%;
    max-width: 100%;

    z-index: 5;

    background-color: #000000AA;
`;

const CancelledMessage = styled.div`

`;

export const Offers = styled.div`
    width: 100%;
    height: 437px;

    flex-shrink: 0;

    display: flex;
    justify-content: center;
`;

const Offer = styled.div`
    position: relative;

    text-align: center;

    display: flex;
    flex-direction: column;

    width: 278px;
    height: 436px;
    margin-left: 30px;
    margin-right: 30px;

    background-image: url('https://s3-us-west-1.amazonaws.com/a-township-tale/shop/assets/SupportButton.png');
    background-repeat: no-repeat;

    border-radius: 5px;

    transition: 200ms;

    &:hover
    {
        transform: scale(1.05);
        cursor: pointer;
    }
`;

const OfferTitle = styled.div`
    position: absolute;
    
    text-transform: uppercase;
    color: white;

    width: 100%;
    font-size: 36px;
    line-height: 80px;

    text-align: center;
    font-weight: normal;
`;

const OfferSaving = styled.div`
    position: absolute;

    margin-top: 80px;

    color:#FFC299;

    width: 100%;
    font-size: 40px;
    line-height: 80px;

    text-align: center;
    font-weight: normal;
`;

const OfferPrice = styled.div`
    position: absolute;

    margin-top: 175px;

    color:#EDEDED;

    width: 100%;
    font-size: 60px;
    line-height: 60px;

    text-align: center;
    font-weight: normal;
`;

const PerMonth = styled.div`
    position: absolute;

    margin-top: 230px;

    color:#EDEDED;

    width: 100%;
    font-size: 32px;
    line-height: 32px;

    text-align: center;
    font-weight: normal;
`;

const OfferFooter
 = styled.div`
    position: absolute;

    margin-top: 315px;

    color: #491D0F;

    width: 100%;
    font-size: 28px;
    line-height: 28px;

    text-align: center;
    font-weight: normal;
`;  

export var OfferButton = ({title, saving, monthly, footer, onClick}) =>
{
    return (
    <Offer key={title} onClick={onClick}>
        <OfferTitle>{title}</OfferTitle>
        <OfferSaving>{!!saving ? `SAVE ${saving}%` : ""}</OfferSaving>
        <OfferPrice>{`$${monthly}`}</OfferPrice>
        <PerMonth>per month</PerMonth>
        <OfferFooter>{footer}</OfferFooter>
    </Offer>);
};

export const SupporterPanel = styled.div`

    position: relative;

    margin: auto;

    margin-top: 90px;

    width: 1272px;
    height: 387px;

    background-image: url('https://s3-us-west-1.amazonaws.com/a-township-tale/shop/assets/SupportDialog.png');
    background-repeat: no-repeat;
`;

export const PanelText = styled.div`

    padding: 65px 100px 120px 100px;

    color: black;

    font-size: 36px;
    line-height: 48px;

    text-align: center;

`;


export const CancelButton = styled.div`
    position: absolute;

    background-image: url('https://s3-us-west-1.amazonaws.com/a-township-tale/shop/assets/SupportDialogButton.png');
    background-repeat: no-repeat;

    width: 369px;
    height: 60px;

    bottom: 40px;
    right: 40px;

    text-align: center;
    line-height: 60px;

    font-size: ${props => props.isPrimary ? '36px' : '24px'};
    font-weight: bold;

    &:hover
    {
        cursor: pointer;
        transform: scale(1.05);
    }

    &:active
    {
        filter:brightness(0.9);
    }
`;

export const RenewButton = styled(CancelButton)`
    filter:hue-rotate(150deg);
    
    &:active
    {
        filter:hue-rotate(150deg) brightness(0.9);
    }
`;