import React from 'react'
import styled from 'styled-components'

const Btn = styled.button`
  width: 40px;
  height: 40px;

  position: absolute;
  top: 0;
  right: -40px;

  display: flex;
  justify-content: center;
  align-items: center;

  &>*{
    pointer-events: none;
  }
`

export default ({
  val = 0,
  handleClick = evt => console.log(`handleRemoveClick: ${evt.target.value}`)
}) =>
  <Btn
    type="button"
    value={val}
    onClick={handleClick}
  >
    <i className="material-icons">close</i>
  </Btn>
