import React from 'react'
import styled from 'styled-components'
import { yellow1 } from '../styles'

const common = () => `
  color: ${yellow1};
`

const H2 = styled.h2`
  ${common()}
  margin-bottom: 32px;

  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0;
  text-align: center;
`

const H3 = styled.h3`
  ${common()}
  margin-bottom: 8px;
  font-size: 28px;
  line-height: 28px;
`

export default ({
  txt = "heading",
  cat = "section"
}) =>
  (() => {
    switch (cat) {
      case 'section':
        return <H2>{txt}</H2>

      case 'sectionSub':
        return <H3>{txt}</H3>

      default: throw new Error(`Unknown heading category: ${cat}.`)
    }
  })()
