import React, { Component } from 'react'
import SERVER_URL from '../SERVER_URL'
import {
  cap1stLetter,
  isEmail,
  googleAnalytics,
  facebookPixel,
} from '../util'
import View from './View'

export default class Pay extends Component {
  state = {
    isEmailFocus: false,
    email: '',
    emailErr: null,

    isAwaitingResponse: false,
    isSuccess: false,
    serverErr: null
  }

  handleFocus = evt => {
    this.setState({
      ...this.state,
      [`is${cap1stLetter(evt.target.name)}Focus`]: true
    })
  }

  handleBlur = evt => {
    this.setState({
      ...this.state,
      [`is${cap1stLetter(evt.target.name)}Focus`]: false
    })
  }

  handleInput = evt => {
    this.setState({
      ...this.state,
      [evt.target.name]: evt.target.value,
      [`${evt.target.name}Err`]: null
    })
  }

  handleSubmit = evt => {
    var status
    googleAnalytics({ evtCat: '/Home/JoinTheTownship/submitBtn' })
    facebookPixel('/Home/JoinTheTownship/submitBtn', 'click')

    evt.preventDefault()

    if (!this.isInputValid()) {
      return
    }

    this.toggleAwaitingResponse()

    fetch(
      `${SERVER_URL}/newsletter_sign_up`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name:  'field removed',
          email: this.state.email
        })
      }
    )
    .then(res => {
      this.toggleAwaitingResponse()
      status = res.status
      return res.json()
    })
    .then(resJSON =>
      status === 200
        ? this.setSuccess()
        : this.setServerErr(resJSON.msg)
    )
    .catch(err => {
      this.setServerErr(`
        There was a problem connecting the the server :(
        Please try again later.
      `)
    })
  }

  toggleAwaitingResponse = () => {
    this.setState({
      ...this.state,
      isAwaitingResponse: !this.state.isAwaitingResponse
    })
  }

  isInputValid = () => {
    if (!isEmail(this.state.email)) {
      this.setState({ emailErr: 'Please enter a valid email.' })
      return false
    }
    else return true
  }

  setSuccess = () => {
    this.setState({
      ...this.state,
      isSuccess: true
    })
  }

  setServerErr = msg => {
    this.setState({
      ...this.state,
      serverErr: msg
    })
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        handleFocus={this.handleFocus}
        handleBlur={this.handleBlur}
        handleInput={this.handleInput}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}
