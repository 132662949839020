import React from 'react'
import styled from 'styled-components'
import {
  BTN_TXT_COLOR,
  ERR_COLOR,
  BTN_SHADOW,
  BTN_HOVER_SHADOW,
  BTN_ACTIVE_SHADOW,
  BTN_HOVER_SCALE,
  BTN_ACTIVE_SCALE,
  BTN_DURATION,
  BTN_EASING,
  blue,
  yellow1,
} from '../styles'
import { btnBackground } from '../imgs'
import {
  Loader
} from '../Common'

const Wrapper = styled.div`
  width: 100%;
  height: 96px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const ErrMsg = styled.p`
  color: ${ERR_COLOR};
`

const SuccessP = styled.p`
  color: ${blue};
`

const Btn = styled.button`
  min-height: 40px;
  min-width: 40px;
  padding: 16px 48px;

  text-transform: uppercase;
  white-space: nowrap;
  color: ${BTN_TXT_COLOR};
  background-image: url(${btnBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: ${BTN_SHADOW};

  &:hover{
    filter: ${BTN_HOVER_SHADOW};
    transform: scale(${BTN_HOVER_SCALE});
  }

  &:active{
    filter: ${BTN_ACTIVE_SHADOW};
    transform: scale(${BTN_ACTIVE_SCALE});
  }

  will-change: filter, transform;
  transition: all ${BTN_DURATION}ms ${BTN_EASING};

  &>span{
    font-weight: 700;

    &>svg{
      fill: ${BTN_TXT_COLOR};
    }
  }
`

const ClearBtn = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${yellow1};
`

export default ({
  isAwaitingResponse = false,
  error = null,
  successMsg = null,
  handleSubmit = () => console.log('handleSubmit'),
  handleClear = () => console.log('handleClear'),
  children = 'submit',
  type = 'submit'
}) => {
  const isErr = !!error
  const isSuccess = !!successMsg

  return (
    <Wrapper>
      {isAwaitingResponse ? <Loader />
        : isErr ? <ErrMsg>{error}</ErrMsg>
        : isSuccess ? <SuccessP>{successMsg}</SuccessP>
        : <Btn type={type} onClick={handleSubmit}>{children}</Btn>
      }
      {(isErr || isSuccess) &&
        <ClearBtn type="button" onClick={handleClear}>
          <i className="material-icons">close</i>
        </ClearBtn>
      }
    </Wrapper>
  )
}
