export const durationLight = 150
export const durationHeavy = 600

// http://easings.net/
export const easingEnterLight = 'cubic-bezier(0.39, 0.575, 0.565, 1)'   /* easeOutSine */
export const easingMoveLight  = 'cubic-bezier(0.445, 0.05, 0.55, 0.95)' /* easeInOutSine */
export const easingExitLight  = 'cubic-bezier(0.47, 0, 0.745, 0.715)'   /* easeInSine */

export const easingEnterHeavy = 'cubic-bezier(0.215, 0.61, 0.355, 1)'     /* easeOutCubic */
export const easingMoveHeavy  = 'cubic-bezier(0.645, 0.045, 0.355, 1)'    /* easeInOutCubic */
export const easingExitHeavy  = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'  /* easeInCubic */

export const routeTransition = () => `
  &.transition-enter {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  &.transition-enter.transition-enter-active {
    opacity: 1;
    transition: opacity ${durationHeavy}ms ${easingEnterHeavy} ${durationHeavy}ms;
  }

  &.transition-leave {
    opacity: 1;
  }

  &.transition-leave.transition-leave-active {
    opacity: 0;
    transition: opacity ${durationHeavy}ms ${easingExitHeavy};
  }
`

export const BTN_DURATION = durationLight
export const BTN_EASING = easingMoveLight
