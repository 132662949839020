import React from 'react'
import styled, {css} from 'styled-components'
import {
  //PRIMARY_TXT_COLOR,
  CLICKABLE_COLOR,
  //BTN_DURATION,
  //BTN_EASING,
  durationLight,
  easingMoveLight
} from '../styles'

const FADED_CLICKABLE_COLOR = 'hsla(39, 100%, 57%, 0.5)'

const Box = styled.input`
position: absolute;
opacity: 0;
cursor: pointer;
`

const Mark = styled.span`
position: relative;
display: inline-block;
top: 0;
left: 0;
height: 15px;
width: 15px;
background-color: transparent;
outline: 1px solid ${CLICKABLE_COLOR};

cursor: pointer;
pointer-events: none;

will-change: background-color;
transition: all ${durationLight}ms ${easingMoveLight};

margin-right: 8px;

&:after 
{
    content: "";
    position: relative;
    display: none;
    left: 5px;
    top: 3px;
    width: 3px;
    height: 6px;
    border: solid ${CLICKABLE_COLOR};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
    ${props => props.isChecked && css`
    display: block;
    `}
}

${props => props.isChecked && css`
    background-color: transparent;
`}
`

const Label = styled.label`
  display: inline-block;
  line-height: 16px;
  color: ${CLICKABLE_COLOR};
  text-transform: uppercase;
  cursor: pointer;
  font-size:15px;
  vertical-align: middle;

   will-change:  margin-left;
   transition: all ${durationLight}ms ${easingMoveLight};
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  
  -webkit-app-region: no-drag;

  height: 25px;

  ${props => props.isDisable && css`
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  `}

  &:hover ${Mark}
  {
    background-color: ${FADED_CLICKABLE_COLOR};
  }

  &:hover ${Label}
  {
    margin-left: 2px;
    margin-right: 2px;
  }
`

export default ({
  label = 'label',
  isChecked = undefined,
  isDisabled = false,
  handleClick = () => console.log('handleClick'),
}) =>
{
    if (isChecked === undefined)
    {
        return (
            <Wrapper isDisable={isDisabled}>
                <Box
                    type="checkbox"
                    name={label}
                    id={label}
                    value={label}
                    checked={false}
                    onChange={handleClick}
                />
                <Label
                    htmlFor={label}
                    isChecked={isChecked}
                >
                {label}
                </Label>
            </Wrapper>);
    }
    else
    {
        return (
            <Wrapper isDisable={isDisabled}>
                <Box
                    type="checkbox"
                    name={label}
                    id={label}
                    value={label}
                    checked={isChecked}
                    onChange={handleClick}
                />
                <Mark 
                    isChecked={isChecked}
                />
                <Label
                    htmlFor={label}
                    isChecked={isChecked}
                >
                {label}
                </Label>
            </Wrapper>);
    }
}
