// @flow
/* eslint no-unused-vars: "off", no-useless-computed-key: "off"  */
import React, { Component } from 'react';

import styled, {css} from 'styled-components'

const PopupBackground = styled.div`
    position: fixed;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.2);
`;

const Popup = styled.div`
    position: relative;

    width: 300px;
    height: 200px;

    margin-left: -150px;
    margin-top: -100px;

    top: 50%;
    left: 50%;

    background-color: #1e1e1e;
    color: white;
`;

const PopupTitle = styled.div`
    position: absolute;

    margin-top: 20px;
    width: 100%;
    text-align: center;


    font-size: 32px;
    line-height: 40px;

    font-weight: bold;
`;

const PopupContent = styled.div`
    position: absolute;

    margin-left: 20px;
    margin-right: 20px;

    width: calc(100% - 40px);

    text-align: center;

    margin-top: 75px;

    font-size: 20px;
`;

const PopupButtons = styled.div`
    position: absolute;

    display: flex;
    justify-content: space-around;

    bottom: 0px;
    height: 40px;
    width: calc(100% - 40px);

    padding: 20px;
`;

const PopupButton = styled.div`
    flex-grow: 0;

    width: 100px;
    height: calc(100% - 4px);

    text-align: center;
    line-height: 40px;

    font-weight: bold;

    background-color: var(--primary);
    color: var(--secondary);

    border-style: solid;
    border-width: 2px;
    border-color: var(--primary);
    
    transition-duration: 100ms;

    &:hover
    {        
        cursor: pointer;

        font-weight: bolder;

        background-color: var(--secondary);
        color: var(--primary);

        transition-duration: 100ms;
    }
`;

export default class PopupComponent extends Component
{
    static defaultProps = 
    {
        title: "Are you sure?",
        content: "Are you sure you want to delete EVERYTHING?",
        buttonA: "Yes",
        buttonB: null,
        aClicked: () => console.log("Button A clicked"),
        bClicked: () => console.log("Button B clicked"),
        buttonAColor: '#BE6324',
        buttonBColor: '#B7482C',
        buttonATextColor: 'white',
        buttonBTextColor: 'white',
    }

    state = {}

    render()
    {
        return (
            <PopupBackground>
                <Popup>
                    <PopupTitle>{this.props.title}</PopupTitle>
                    <PopupContent>{this.props.content}</PopupContent>

                    <PopupButtons>
                        <PopupButton style={{["--primary"]:this.props.buttonAColor, ["--secondary"]:this.props.buttonATextColor}} onClick={this.props.aClicked}>{this.props.buttonA}</PopupButton>
                        {!this.props.buttonB ? null : 
                        <PopupButton style={{["--primary"]:this.props.buttonBColor, ["--secondary"]:this.props.buttonBTextColor}} onClick={this.props.bClicked}>{this.props.buttonB}</PopupButton>}
                    </PopupButtons>
                </Popup>
            </PopupBackground>
        );
    }
}
