// @flow
/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react';

import sha512 from 'crypto-js/sha512'

import { Sessions, Users } from '../../webApiClient';

import {
    RouteWrapper,
    Heading,
    Form,
    TxtInput,
    Submit,
    Icon,
    SmallLink,
    Row
  } from '../../Common'
  
import {
    isName,
    isEmail,
    isPassword,
  } from '../../util'

export default class VerifyPage extends Component
{
    static defaultProps = 
    {
        email:""
    }

    state = 
    {
        isValid: false,
        isDone: false,
        isVerified: false,
        
        email:
        {
            name:"email",
            value:this.props.email,
            error:"",
            isFocused:false,
            isDisabled:false,
        }
    }

    componentWillMount()
    {        
        this.repoll();
    }

    repoll()
    {
        this.timeout = setTimeout(() =>
        {
            Users.getVerified()
            .then(body => 
            {
                if (body && !this.state.isVerified)
                {
                    this.setState({isVerified:true});

                    this.props.history.push("/");
                }
            });

            if (!this.state.isVerified)
            {
                this.repoll();
            }
        },
        10000);
    }

    componentWillReceiveProps(nextProps)
    {

    }

    componentWillUnmount()
    {
        if (!!this.timeout)
        {
            clearTimeout(this.timeout);
        }
    }

    handleFocus = evt => 
    {
        const { name } = evt.target
    
        this.setState((prevState, props) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            isFocused: true,
          }
       }))
    }
      
    handleEmail = evt =>
    {
        const { name, value } = evt.target
        
        var newState = {};

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: null
        };
        
        newState.username =
        {
            ...this.state.username,
            isDisabled: value !== "",
        }

        newState.isValid = isEmail(value);
        
        if (!newState.isValid && value !== 0)
        {
            newState[name].error = "This is not a valid email";
        }

        this.setState(newState);
    }

    handleBlur = evt =>
    {
        const { name } = evt.target

        this.setState((prevState, props) => ({
        ...prevState,
        [name]: {
            ...prevState[name],
            isFocused: false,
        }
        }))
    }

    handleLogout(evt)
    {
        evt.preventDefault();

        Sessions.logout();

        this.props.history.push('/account/login');
    }

    handleResend(evt)
    {
        evt.preventDefault();
        
        if (!isEmail(this.state.email.value))
        {
            return;
        } 

        Users.requestVerificationEmail(this.state.email.value);

        this.setState({isDone: true});
    }

    render()
    {
        return (
            <RouteWrapper>
                <Heading txt="Please check your emails!" />
                <Form>
                    {this.state.isDone?null:<h1>If you did not receive an email, reset it here.</h1>}
                                            
                    {this.state.isDone?null:<TxtInput {...this.state.email} handleFocus={this.handleFocus} handleInput={this.handleEmail.bind(this)} handleBlur={this.handleBlur}/>}

                    <Row size='calc(9 * 48px)' justify='space-between'>
                        <Submit type={'button'} handleSubmit={this.handleLogout.bind(this)}>Log Out</Submit>
                        {this.state.isDone?null:<Submit handleSubmit={this.handleResend.bind(this)} disabled={this.state.isProcessing || !this.state.isValid}>Resend</Submit>}
                    </Row>
                </Form>
            </RouteWrapper>);
    }
}