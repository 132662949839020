import React from 'react'

import banner from './imgs/banner.png'
import logo from './imgs/logo.png'

import Wrapper from './Wrapper'
import Banner from './Banner'
import Logo from './Logo'
import BottomBackground from './BottomBackground'
import CTABtn from './CTABtn'

export default ({
  handleCTAClick = () => console.log('handleCTAClick'),
}) =>
  <Wrapper>
    <Banner
      src={banner}
      alt=""
    />
    <Logo
      src={logo}
      alt="A Township Tale"
    />
    <BottomBackground />
    <CTABtn onClick={handleCTAClick}>
      <span>play</span>
    </CTABtn>
  </Wrapper>
