import React from 'react'
import styled, {css} from 'styled-components'
import {
  PRIMARY_TXT_COLOR,
  CLICKABLE_COLOR,
  SELECTED_TEXT_SCALE,
  BTN_DURATION,
  BTN_EASING,
} from '../styles'

const Wrapper = styled.div`
  display: flex;
`

const P = styled.p`
  margin: 16px;
`

const RadioInput = styled.input`
  display: none;
`

const Label = styled.label`
  color: ${CLICKABLE_COLOR};
  text-transform: uppercase;
  cursor: pointer;

  ${props => props.isChecked && css`

  ${'' /* required for scale */}
    display: block;
    transform: scale(${SELECTED_TEXT_SCALE});
    color: ${PRIMARY_TXT_COLOR};
    pointer-events: disabled;
    cursor: default;
  `}

  transition: all ${BTN_DURATION}ms ${BTN_EASING};
`

export default ({
  name = 'name',
  values = ['input1', 'input2'],
  checkedVal = 'input1',
  handleClick = () => console.log('handleClick'),
}) =>
  <Wrapper>
    {values.map((value, i) => {
      const id = `${name}Choice${i}`
      const isChecked = value === checkedVal

      return (
        <P key={value}>
          <RadioInput
            type="radio"
            name={name}
            value={value}
            id={id}
            checked={isChecked}
            onChange={handleClick}
          />
          <Label
            htmlFor={id}
            isChecked={isChecked}
          >
            {value}
          </Label>
        </P>
      )
    })}
  </Wrapper>
