import React from 'react'
import styled from 'styled-components'
import {
  durationLight,
  easingMoveLight,
  shadow5,
  shadow3,
} from '../styles'

const Img = styled.img`
  width: ${props => props.size};
  filter: ${shadow5};

  &:hover{
    filter: ${shadow3};
    transform: scale(1.05);
  }

  will-change: filter, transform;
  transition: all ${durationLight}ms ${easingMoveLight};
`

export default ({
  img,
  size = '100%',
  descr = "",
}) =>
  <Img
    src={img}
    size={size}
    alt={descr}
  />
