import React, { Component } from 'react'
import { Observable } from 'rxjs'
import SERVER_URL from '../SERVER_URL'
import {
  genTodaysTimestamp,
  dateStrToTimestamp,
  dayMonthYearToTimestamp,
} from '../util'
import View from './View'

export default class Blog extends Component {
  state = {
    isAwaitingInitialPost: true,
    isAwaitingNextPost: false,
    blogPosts: [],
    isMorePosts: true,
    serverErr: null
  }
  fetchPost = this.fetchPost.bind(this)
  setAwaitingNextPost = this.setAwaitingNextPost.bind(this)

  componentWillMount() {
    const { year, month, day } = this.props.match.params

    const timestamp = !!day
      ? dayMonthYearToTimestamp(day, month, year)
      : genTodaysTimestamp()

    this.fetchPost(timestamp)
  }

  componentDidMount() {
    this.scroll$Sub = Observable.fromEvent(window, 'scroll')
      .map(evt => window.pageYOffset)
      .filter(scrollY => (window.innerHeight + scrollY + 100) >= document.body.scrollHeight)
      .filter(() => !this.state.isAwaitingNextPost)
      .filter(() => this.state.isMorePosts)
      .filter(() => this.state.blogPosts.length > 0)
      .subscribe(() => {
        const lastPost = this.state.blogPosts[this.state.blogPosts.length - 1]
        const timestamp = lastPost.enteredAt
        this.setAwaitingNextPost()
        this.fetchPost(timestamp - 1)
      })
  }

  componentWillUnmount() {
    this.scroll$Sub.unsubscribe()
  }

  fetchPost(timestamp = 1504718409) {
    fetch(`${SERVER_URL}/read-blog-post/${timestamp}`)
    .then(res => {
      if (res.status !== 200) {
        throw Error(res)
      }

      return res.json()
    })
    .then(json => {
      //console.log(json);
      this.setState({
        ...this.state,
        isAwaitingInitialPost: false,
        isAwaitingNextPost: false,
        blogPosts: [
          ...this.state.blogPosts,
          json
        ],
        isMorePosts: !json.isLastPost
      })
    })
    .catch(err => {
      console.log('err: ', err)
      this.setState({
        ...this.state,
        serverErr: 'There was a problem connecting the the server. Please try again later.'
      })
    })
  }

  setAwaitingNextPost() {
    this.setState({
      ...this.state,
      isAwaitingNextPost: true
    })
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
      />
    )
  }
}
