import React from 'react'
import {
  Heading,
} from '../Common'
import Wrapper from './Wrapper'
import Btns from './Btns'
import Description from './Description'

export default ({
  data = [],
  selectedName,
  scene,
  description,
  handleClick,
}) => {
  const {
    scene: selectedScene,
    description: selectedDescription
  } = data.find(entry => entry.name === selectedName)

  return (
    <Wrapper>
      <Heading txt="Roles" />
      <Btns
        data={data}
        selectedName={selectedName}
        handleClick={handleClick}
      />
      <Description
        img={selectedScene}
        txt={selectedDescription}
      />
    </Wrapper>
  )
}
