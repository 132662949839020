/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react'
import { Observable } from 'rxjs'
import SERVER_URL from '../SERVER_URL'
import {
  genTodaysTimestamp,
  dateStrToTimestamp,
} from '../util'
import View from './View'

export default class PreviewBlog extends Component {
  state = 
  {
    input: "",
    serverErr: null
  }
  
  inputCleared = this.inputCleared.bind(this);
  inputChanged = this.inputChanged.bind(this);

  inputCleared(evt)
  {
    this.setState({...this.state, input:""});
  }

  inputChanged(evt) 
  {
    this.setState({...this.state, input:evt.target.value});
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        inputChanged={this.inputChanged}
        inputCleared={this.inputCleared}
      />
    )
  }
}
