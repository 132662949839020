import styled from 'styled-components'

const IMG_WIDTH = 1920

export default styled.img`
${'' /* imgs are set to max-width 100% in global */}
  max-width: unset;
  display: block;

  position: absolute;
${'' /* provides room for the signUp btn */}
  bottom: 48px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

${'' /* img stretches when display's width is @ the img's native width */}
  @media (min-width: ${IMG_WIDTH}px) {
    width: 100%;
  }
`
