import React from 'react'
import {
  RouteWrapper,
  Heading,
} from '../Common'
import Txt from './Txt'
import Links from './Links'

export default ({
  handleLinkClick = () => console.log('handleLinkClick'),
}) =>
  <RouteWrapper>
    <Heading txt="Follow Us" />
    <Txt />
    <Links handleLinkClick={handleLinkClick}/>
  </RouteWrapper>
