import React from 'react'
import styled from 'styled-components'
import schmeechee from './imgs/schmeechee.svg'

const Img = styled.img`
  margin-top: 48px;
  width: 288px;
`

export default () =>
  <Img
    src={schmeechee}
    alt=""
  />
