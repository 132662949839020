import React, { Component } from 'react'
import {
  googleAnalytics,
  facebookPixel,
} from '../util'
import View from './View'

export default class BlogPost extends Component {
  static defaultProps = {
    title: 'title',
    author: 'author',
    enteredAt: 1234567890,
    content: [],
    isHideShareBtns: false,
  }
  state = {
    isShowUrl: false,
  }

  handleUrlClick = title => {
    googleAnalytics({ evtCat: `/Blog/${title}/ShowUrlBtn` })
    facebookPixel(`/Blog/${title}/ShowUrlBtn`, 'click')

    this.setState({
      ...this.state,
      isShowUrl: true,
    })
  }

  handleShareBtnClick = (blogPostTitle, socialNetwork) => {
    googleAnalytics({ evtCat: `/Blog/${blogPostTitle}/${socialNetwork}ShareBtn` })
    facebookPixel(`/Blog/${blogPostTitle}/${socialNetwork}ShareBtn`, 'click')
  }

  render = () => {
    return (
      <View
        {...this.props}
        {...this.state}
        handleUrlClick={this.handleUrlClick}
        handleShareBtnClick={this.handleShareBtnClick}
      />
    )
  }
}
