import React from 'react'
import styled from 'styled-components'
import {
  TOOLBAR_Z_INDEX,
  TOOLBAR_LINK_HEIGHT,
  TOOLBAR_FOOTER_IMG_HEIGHT,
  brown1,
  durationHeavy,
  easingMoveHeavy,
} from '../styles'

const TRANSFORM_Y = `calc(-100% + ${TOOLBAR_LINK_HEIGHT + TOOLBAR_FOOTER_IMG_HEIGHT}px)`

const Header = styled.header`
  width: 100%;
  height: ${TOOLBAR_LINK_HEIGHT * 5 + TOOLBAR_FOOTER_IMG_HEIGHT}px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: ${TOOLBAR_Z_INDEX};

  background: linear-gradient(to bottom,
    ${brown1} 94%,
    hsla(0,0%,0%,0) 95%
  );
  transform: translate3d(0, ${props => props.isDown ? 0 : TRANSFORM_Y}, 0);
  will-change: transform;
  transition: transform ${durationHeavy}ms ${easingMoveHeavy};
`

export default ({
  numOfBtns,
  isDown,
  children,
}) =>
  <Header
    id="toolbar"
    isDown={isDown}
  >
    {children}
  </Header>
