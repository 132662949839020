import { css } from 'styled-components'
import {
  yellow2,
  // yellow1
} from './colors'

export default css`
  .StripeElement {
    width: calc(9 * 48px);
    max-width: 100%;
    height: 40px;

    padding: 8px;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    border-bottom: 1px solid ${yellow2};
  }

  .StripeElement--focus {

  }
`
