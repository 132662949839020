import React from 'react'
import styled from 'styled-components'
import {
  TOOLBAR_FOOTER_IMG_HEIGHT,
} from '../styles'
import {

} from '../imgs'
import {
  Icon,
} from '../Common'

const Btn = styled.button`
  width: 40px;
  height: 40px;

  position: absolute;
  bottom: ${TOOLBAR_FOOTER_IMG_HEIGHT}px;
  left: 50%;

${'' /* 4px to account for footer img */}
  transform: translate3d(-50%, 4px, 0);
`

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({
  handleClick = () => console.log('handleClick'),
}) =>
  <Btn
    onClick={handleClick}
    type="button"
  >
    <Div>
      <Icon cat="menu" />
    </Div>
  </Btn>
