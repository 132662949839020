import React from 'react'
import styled from 'styled-components'
import {
  twitter,
  discord,
  facebook,
  youtube,
} from '../imgs'
import {
  ClickableImg,
} from '../Common'

const Wrapper = styled.div`
  width: 100%;
  max-width: calc(10 * 48px);
  display: flex;
  justify-content: space-between;

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
  }
`

const A = styled.a`
  margin: 24px;
`

export default ({
  handleLinkClick = () => console.log('handleLinkClick'),
}) =>
  <Wrapper>
    <A
      onClick={handleLinkClick.bind(this, 'Twitter')}
      href="https://twitter.com/townshiptale"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ClickableImg
        img={twitter}
        size="96px"
        descr="twitter"
      />
    </A>
    <A
      onClick={handleLinkClick.bind(this, 'Facebook')}
      href="https://www.facebook.com/townshiptale"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ClickableImg
        img={facebook}
        size="96px"
        descr="facebook"
      />
    </A>
    <A
      onClick={handleLinkClick.bind(this, 'Discord')}
      href="https://discord.gg/ppEAy7D"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ClickableImg
        img={discord}
        size="96px"
        descr="discord"
      />
    </A>
    <A
      onClick={handleLinkClick.bind(this, 'Youtube')}
      href="https://youtube.com/townshiptale"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ClickableImg
        img={youtube}
        size="96px"
        descr="youtube"
      />
    </A>
  </Wrapper>
