import styled from 'styled-components'

export default styled.section`
  width: 100%;
  margin-bottom: 96px;
  padding: 0 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
`
