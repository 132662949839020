import React from 'react'
import styled from 'styled-components'

const Article = styled.article`
  width: 100%;
  max-width: 640px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ({
  children = 'ArticleChild',
}) =>
  <Article itemscope itemtype="https://schema.org/BlogPosting">{children}</Article>
