import moment from 'moment'

export default ({
  timestamp = '1234567890',
  title = 'title',
} = {}) => {
  const year = moment.unix(timestamp).utc().year()
  // months are 0 indexed
  const month = moment.unix(timestamp).utc().month() + 1
  const day = moment.unix(timestamp).utc().date()

  const formattedTitle = title
    .replace(/'/g, "")
    .replace(/[^A-Za-z0-9-]+/g, '-')
    .replace(/-+$/, "")
    .replace(/^-+/, "")
    .replace(/-+/g, '-')
    .toLowerCase()

  return `https://townshiptale.com/blog/${year}/${month}/${day}/${formattedTitle}`
}
