import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  durationLight,
  easingMoveLight,
  durationHeavy,
  easingMoveHeavy,
  yellow1,
  white2,
  TOOLBAR_LINK_HEIGHT,
  TOOLBAR_FOOTER_IMG_HEIGHT,
} from '../styles'
import {
  Icon,
} from '../Common'

const Ul = styled.ul`
  height: calc(100% - ${TOOLBAR_FOOTER_IMG_HEIGHT}px);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${props => props.isMobile && css`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `}
`

const Li = styled.li`
  margin: 0 24px;
${'' /* to account for footer img */}
  transform: translate3d(0, 4px, 0);

  &:hover{
    transform: scale(1.06) translate3d(0, 4px, 0);
  }
  will-change: transform;
  transition: transform ${durationLight}ms ${easingMoveLight};
`

const Span = styled.span`
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  line-height: ${TOOLBAR_LINK_HEIGHT}px;

  color: ${props => props.isYellow ? yellow1 : 'unset'};
  will-change: color;
  transition: color ${durationHeavy}ms ${easingMoveHeavy};
`

export default ({
  currentPathname,
  data,
  isMobile,
  handleLinkClick,
}) =>
  <Ul isMobile={isMobile}>
    {data.map(entry => {
      const { pathname, iconCat, txt } = entry
      const isSelected = pathname === currentPathname
      const shade = isSelected ? yellow1 : white2

      return (
        <Li key={pathname}>
          <Link
            onClick={handleLinkClick.bind(this, txt)}
            to={pathname}
          >
            <Span isYellow={isSelected}>
              <Icon
                cat={iconCat}
                shade={shade}
                isLower
              />
              &nbsp;
              {txt}
            </Span>
          </Link>
        </Li>
      )
    })}
  </Ul>
