import React, { Component } from 'react';

import { Shop, Sessions } from './webApiClient';

import Checkout from './Checkout';

// Create a new context for the layout
const Context = React.createContext();

// Creates a provider Component
class Provider extends Component
 {
    constructor(props)
    {
        super(props);
        
        this.state = 
        {

        };
    }

    componentDidMount()
    {
        this.refresh();
    }

    refresh()
    {
        try
        {
            this.refreshPublic();
            
            if (Sessions.getLoggedIn())
            {
                Shop.Account.getRewards()
                .then(data =>
                {
                    this.setState({reward : data});
                    
                    Shop.getSandbox().then(response => 
                    {
                        this.setState({sandbox: response.sandbox});
                        
                        this.refreshContent();
                    });
                });
            }
       }
       catch (e)
       {
           console.log(e);  
       }
    }

    refreshPublic()
    {        
        Shop.Subscriptions.getSubscriptions()
        .then(data => this.setState({subscriptions: data}));
    }

    refreshContent()
    {
        Shop.Account.getItems()
        .then(data => this.setState({owned: data}));

        Shop.Account.getProfile()
        .then(data => this.setState({profile: data}));
        
        Shop.Account.getSubscription()
        .then(data => this.setState({subscription: data}));
        
        Shop.Shards.getPackages()
        .then(data => this.setState({shards: data}));
    }

    fullProfileRefresh()
    {   
        Sessions.refreshSession()
        .then(result =>
        {     
            Shop.Account.getItems()
            .then(data => this.setState({owned: data}))
            .catch(err =>
            {
                console.log(err);
            });

            Shop.Account.getProfile()
            .then(data => this.setState({profile: data}))
            .catch(err =>
            {
                console.log(err);
            });
        
            Shop.Account.getSubscription()
            .then(data => this.setState({subscription: data}))
            .catch(err =>
            {
                console.log(err);
            });
        })
        .catch(err =>
        {
            console.log(err);
        });
    }
    

    queueRefreshes()
    {
        for (var i = 0; i < 5; i++)
        {
            setTimeout(() =>
            {            
                this.fullProfileRefresh();
            },
            i * 2000);
        }
    }
    
    showCheckout(token)
    {
        this.showCheckoutInternal(token);
    }

    render() 
    {
        return (
            <Context.Provider value={{...this.state, fullRefresh : this.fullProfileRefresh.bind(this), refreshShopMultiple: this.queueRefreshes.bind(this), refreshShop : this.refreshContent.bind(this), showCheckout: this.showCheckout.bind(this)}}>
                {this.props.children}
                <Checkout setShowMethod={(show) => this.showCheckoutInternal = show} onDone={this.queueRefreshes.bind(this)}/>
            </Context.Provider>
        );
    }
}

function withInfo(Component)
{
    return function WrapperComponent(props) {
        return (            
            <Context.Consumer>
                {value => <Component {...props} context={{...props.context, ...value}} />}
            </Context.Consumer>
        );
    };
}

const ShopInfo =
{
    Provider : Provider,
    wrap : withInfo   
};

export default ShopInfo;