import React from 'react'
import {
  RouteWrapper,
  Heading,
  RouteLink,
  Icon,
} from '../Common'
import Img from './Img'

export default () =>
  <RouteWrapper>
    <Heading txt="Did You Get Lost?" />
    <RouteLink to="/">
      <Icon cat="home" />
      &nbsp;
      home
    </RouteLink>
    <Img />
  </RouteWrapper>
