import React from 'react'
import styled from 'styled-components'
import {
  shadow6,
} from '../styles'

// could not import this from index (unkonwn why)
const MEDIA_BREAK_POINT = 950

const Wrapper = styled.div`
  display: flex;
${'' /* use flex wrap causes too many issues when margins */}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${MEDIA_BREAK_POINT}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const Img = styled.img`
  filter: ${shadow6};
`

const P = styled.p`
  margin: 24px 0 0 0;
  text-align: justify;

  @media (min-width: ${MEDIA_BREAK_POINT}px) {
    margin: 0 0 0 24px;
  }
`

export default ({
  img = 'img',
  txt = 'txt'
}) =>
  <Wrapper>
    <Img
      src={img}
      alt=""
    />
    <P>{txt}</P>
  </Wrapper>
