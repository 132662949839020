// @flow
/* eslint no-unused-vars: "off"*/
import React, { Component } from 'react';

import sha512 from 'crypto-js/sha512'

import { Sessions, Users } from '../../webApiClient';

import {
    RouteWrapper,
    Heading,
    Form,
    TxtInput,
    Submit,
    Icon,
    SmallLink,
    Row
  } from '../../Common'
  
import {
    isName,
    isEmail,
    isPassword,
  } from '../../util'


export default class RegisterPage extends Component
{
    static defaultProps = 
    {
        
    }

    state = 
    {        
        isProcessing: false,
        isDone: false,

        email:
        {
            name:"email",
            value:"",
            error:"",
            isFocused:false,
            isValid: false,
        },

        username:
        {
            name:"username",
            value:"",
            error:"",
            isFocused:false,
            isValid: false,
        },

        password:
        {
            name:"password",
            value:"",
            error:"",
            isFocused:false,
            isValid: false,
        },

        repeatPassword:
        {
            name:"repeatPassword",
            value:"",
            error:"",
            isFocused:false,
            isValid: false,
        },
    }

    componentWillMount()
    {        
        this.setState({
            username:
            {
                ...this.state.username,
                value: this.props.match.params.username
            }
        })
    }

    componentWillReceiveProps(nextProps)
    {

    }

    componentWillUnmount()
    {

    }

    handleFocus = evt => 
    {
        const { name } = evt.target
    
        this.setState((prevState, props) => ({
          ...prevState,
          [name]: {
            ...prevState[name],
            isFocused: true,
          }
        }))
    }

    handleUsername = evt =>
    {
        const { name, value } = evt.target
        
        var newState = {};

        var error = isName(value);

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: null,
            isValid: !error,
        };

        if (!newState[name].isValid && value !== 0)
        {
            newState[name].error = error;
        }

        this.setState(newState);
    }

    handleEmail = evt =>
    {
        const { name, value } = evt.target
        
        var newState = {};

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: null,
            isValid: isEmail(value),
        };
        

        if (!newState[name].isValid && value !== 0)
        {
            newState[name].error = "This is not a valid email";
        }

        this.setState(newState);
    }

    handlePassword = evt =>
    {
        const { name, value } = evt.target
        
        var newState = {};

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: null,
            isValid: isPassword(value),
        };

        if (!newState[name].isValid && value !== 0)
        {
            newState[name].error = "This is not a valid password";
        }


        newState.repeatPassword =
        {
            ...this.state.repeatPassword,
            error: null,
            isValid: value === this.state.repeatPassword.value,
        };

        if (!newState.repeatPassword.isValid && newState.repeatPassword.value !== 0)
        {
            newState.repeatPassword.error = "Passwords do not match";
        }

        this.setState(newState);
    }
    
    handleRepeatPassword = evt =>
    {
        const { name, value } = evt.target

        var newState = {};

        newState[name] =
        {
            ...this.state[name],
            value: value,
            error: null,
            isValid: value === this.state.password.value,
        };

        if (!newState[name].isValid && value !== 0)
        {
            newState[name].error = "Passwords do not match";
        }

        this.setState(newState);
    }

    handleInput = evt => 
    {
        const { name, value } = evt.target

        this.setState((prevState, props) => ({
        ...prevState,
        [name]: {
            ...prevState[name],
            value: value,
            error: null,
        },
        }))
    }

    handleBlur = evt =>
    {
        const { name } = evt.target

        this.setState((prevState, props) => ({
        ...prevState,
        [name]: {
            ...prevState[name],
            isFocused: false,
        }
        }))
    }

    handleBack(evt)
    {
        evt.preventDefault();

        this.props.history.replace("/account/login");
    }

    handleRegister(evt)
    {
        evt.preventDefault();

        this.setState({isProcessing: true});

        let passwordHash = sha512(this.state.password.value).toString();

        let username = this.state.username.value.trim();

        Users.register(username, passwordHash, this.state.email.value)
        .then (data =>
        {
            console.log("Register succeeded");

            this.setState(
            {
                isProcessing: false,
                isDone: true,
            });
        })
        .catch (error =>
        {
            console.log("Register failed");
            console.log(error);

            var data = JSON.parse(error.error);

            var isErrorEmail = data.message.match(/email/i);
            var isErrorPassword = data.message.match(/password/i);
            var isErrorUsername = data.message.match(/username/i) || (!isErrorEmail && !isErrorPassword);

            this.setState(
            {
                isProcessing: false,

                email:
                {
                    ...this.state.email,
                    error: isErrorEmail ? data.message : null,
                    isValid: !isErrorEmail,
                },
                
                password:
                {
                    ...this.state.password,
                    error: isErrorPassword ? data.message : null,
                    isValid: !isErrorPassword,
                },
                
                username:
                {
                    ...this.state.username,
                    error: isErrorUsername ? data.message : null,
                    isValid: !isErrorUsername,
                }
            });
        });
    }

    render()
    {
        if (this.state.isDone)
        {
            return (
                <RouteWrapper>
                    <Heading txt={`Please activate your account through the email sent to ${this.state.email.value}`} />
                    <Form>
                        <Row size='calc(9 * 48px)' justify='space-around'>
                            <Submit handleSubmit={this.handleBack.bind(this)}>Go to Login</Submit>
                        </Row>
                    </Form>
                </RouteWrapper>);
        }
        else
        {
            return (
            <RouteWrapper>
                <Heading txt="Register" />
                <Form>
                    <TxtInput {...this.state.email}             isDisabled={this.state.isProcessing} handleFocus={this.handleFocus} handleBlur={this.handleBlur} handleInput={this.handleEmail}/>
                    <TxtInput {...this.state.username}          isDisabled={this.state.isProcessing} handleFocus={this.handleFocus} handleBlur={this.handleBlur} handleInput={this.handleUsername}/>
                    <TxtInput {...this.state.password}          isDisabled={this.state.isProcessing} handleFocus={this.handleFocus} handleBlur={this.handleBlur} handleInput={this.handlePassword} isPassword/>
                    <TxtInput {...this.state.repeatPassword}    isDisabled={this.state.isProcessing} handleFocus={this.handleFocus} handleBlur={this.handleBlur} handleInput={this.handleRepeatPassword} isPassword/>
                    
                    <Row size='calc(9 * 48px)' justify='space-around'>
                        <Submit
                            type={'button'}
                            isAwaitingResponse={this.props.isProcessing}
                            error={this.props.error}
                            successMsg={this.props.success}
                            handleSubmit={this.handleBack.bind(this)}>Back</Submit>
                            
                        <Submit
                            isAwaitingResponse={this.props.isProcessing}
                            error={this.props.error}
                            successMsg={this.props.success}
                            handleSubmit={this.handleRegister.bind(this)}>Register</Submit>

                        {/* <Button label={"Back"} onClick={this.handleBack.bind(this)} ripple raised accent disabled={this.state.isProcessing} />
                        <Button label={"Register"} onClick={this.handleRegister.bind(this)} ripple raised primary disabled={this.state.isProcessing ||
                                                                                                                            !this.state.email.isValid ||
                                                                                                                            !this.state.username.isValid ||
                                                                                                                            !this.state.password.isValid ||
                                                                                                                            !this.state.repeatPassword.isValid}/> */}
                    </Row>
                </Form>
            </RouteWrapper>);
        }
    }
}